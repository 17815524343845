import { useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { certificationStatus, ObjectionLevelTitles } from "../../../constant";
import { ImageView } from "../../../components/Image";
import { ReactComponent as StarIcon } from "../../../assets/icons/star.svg";
import { getInitial } from "../../../services/utils";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../services/api";
import { usePraxisCall } from "../../praxis-play/context/praxiscall-context";
import { DataTable } from "../../certifications/components/data-table";
import { useCertification } from "../../certifications/context/certification-context";
import { report } from "../../praxis-call/services/data";
import { secondsToMinute } from "../../praxis-call/utils";
import { ReportView } from "../../praxis-call/components/ReportView";

const filterData = (data: any[], filter: any) => {
  console.log("filter", filter, data);
  let filteredData = [...data];
  if (filter.search) {
    filteredData = filteredData.filter((item) => {
      const searchTerm = filter.search.toLowerCase();
      const certName =
        item?.certification?.certificationName?.toLowerCase() ?? "";
      return certName.includes(searchTerm);
    });
  }
  if (filter.sortBy && filter.sortOrder) {
    filteredData = filteredData.sort((a, b) => {
      if (filter.sortBy === "createdAt") {
        // date filter
        const aDate = moment(a.createdAt).utc().format("YYYY-MM-DD");
        const bDate = moment(b.createdAt).utc().format("YYYY-MM-DD");
        const before =
          filter.sortOrder === "desc"
            ? moment(bDate).isBefore(aDate)
            : moment(aDate).isBefore(bDate);
        return before ? -1 : 1;
      }
      return filter.sortOrder === "desc"
        ? b[filter.sortBy] - a[filter.sortBy]
        : a[filter.sortBy] - b[filter.sortBy];
    });
  }
  console.log("filteredData", filteredData);
  return filteredData;
};

export function StatusCell({ status }: { status: string }) {
  const statusData =
    certificationStatus[status as keyof typeof certificationStatus] ||
    certificationStatus.NotStarted;
  return (
    <div
      className={`rounded-md w-[102px] h-[37px] flex items-center justify-center text-[13px]`}
      style={{
        backgroundColor: statusData?.bgColor,
        color: statusData?.textColor,
      }}
    >
      {statusData?.label}
    </div>
  );
}

function PraxisScoreCell({ score }: { score: number }) {
  const hasScore = score || score === 0;
  const status = !hasScore ? "N/A" : score < 3 ? "Fail" : "Pass";
  const statusData = certificationStatus[
    status as keyof typeof certificationStatus
  ] || {
    label: "N/A",
    bgColor: "transparent",
    textColor: "#C6C6C6",
  };
  return (
    <div
      className={`rounded-md gap-2 w-[102px] h-[37px] flex items-center justify-center text-[13px]`}
      style={{
        backgroundColor: statusData.bgColor,
        color: statusData.textColor,
      }}
    >
      {hasScore && <StarIcon className="w-4 h-4" />}
      {hasScore ? score : "N/A"}
    </div>
  );
}

const getColumns = (
  data: any[],
  { onAction }: { onAction: (row: any) => void }
) => {
  const commonColumns = [
    {
      accessorKey: "objectionLevel",
      header: ({ column }) => {
        return <div className="p-0">Objection Level</div>;
      },
      cell: ({ row }) => {
        return (
          <div>
            {
              ObjectionLevelTitles[
                row.original.objectionLevel as keyof typeof ObjectionLevelTitles
              ]
            }
          </div>
        );
      },
    },
    {
      accessorKey: "finalScore",
      header: "Praxis Score",
      cell: ({ row }) => {
        return (
          <PraxisScoreCell
            score={
              row.original.score?.value ||
              row.original?.report?.constant_metrics?.score?.value ||
              0
            }
          />
        );
      },
    },
    {
      accessorKey: "duration",
      header: "Duration",
      cell: ({ row }) => {
        return `${secondsToMinute(row.original.duration)} mins`;
      },
    },
    {
      accessorKey: "Date Created",
      header: ({ column }) => {
        return <div className="p-0">Date Created</div>;
      },
      cell: ({ row }) => {
        return (
          <span>
            {moment(row.original.createdAt).utc().format("MMM DD, YYYY")}
          </span>
        );
      },
    },

    {
      id: "actions",
      header: () => {
        return <div className="w-full flex justify-end">Session</div>;
      },
      cell: ({ row }) => {
        return (
          <div className="w-full flex justify-end">
            <button
              onClick={() => {
                onAction(row.original);
              }}
              className="text-sm text-center text-[#242424] hover:text-primary2"
            >
              View
            </button>
          </div>
        );
      },
    },
  ];
  return [
    {
      accessorKey: "practitionerType",
      header: ({ column }) => {
        return <div className="p-0">Practitioner Type</div>;
      },
      cell: ({ row }) => {
        return <div>{row.original.practitionerType}</div>;
      },
    },
    ...commonColumns,
  ];
};

type User = {
  id: string;
  name: string;
  email: string;
  joinedOn: string;
  tier: string;
  totalSellers: number;
  status: string;
  statusText: string;
  statusDescription: string;
  totalSpend: string;
  stripeCustomerId: string;
  subscription: any;
  openDrawer: (row: any) => void;
  deleteModal: (row: any) => void;
  deactivateModal: (row: any) => void;
};

export function ReportsTable({
  userId,
  filter,
  onRowClick,
}: {
  userId?: string;
  filter?: any;
  onRowClick: (row: any) => void;
}) {
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const navigate = useNavigate();
  const { setAssignment } = usePraxisCall();
  const {
    certifications: certificationList,
    users,
    setSelectedCertificationAssignment,
  } = useCertification();

  const {
    data: certifications,
    isLoading: isLoadingCertifications,
    refetch,
  } = useQuery({
    queryKey: [`reports`, userId],
    queryFn: () => api.getAssignments({ userId }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const data = useMemo(() => {
    return [
      { ...report },
      { ...report },
      { ...report },
      { ...report },
      { ...report },
    ];
    // const assignments = (certifications?.data?.assignments || []).map(
    //   (assignment) => {
    //     const certification = certificationList.find(
    //       (certification) => certification.id === assignment.certificationId
    //     );
    //     const user = users.find((user) => user.id === assignment.userId);
    //     return {
    //       ...assignment,
    //       certificationName: certification?.name,
    //       certification,
    //       user,
    //     };
    //   }
    // );
    // return assignments;
  }, [certifications, certificationList]);

  const columns: ColumnDef<User>[] = getColumns(data, {
    onAction: (row) => {
      console.log("row", row);
      onRowClick(row);
    },
  });

  return (
    <div className="w-full">
      <div className="flex flex-col gap-5">
        <DataTable
          columns={columns}
          onRowClick={(row) => {
            setSelectedRow(row);
          }}
          data={filterData(data, filter)}
          isLoading={isLoadingCertifications}
        />
      </div>
    </div>
  );
}
