export function AIOverview({ report }) {
  const summary = `<ul><li>The conversation started with the doctor providing a brief overview of Rexulti's indications.</li><li>There was no further discussion or questions from the sales representative, so key areas such as efficacy, safety data, or tangible benefits to patients were not covered.</li><li>The conversation remained within approved indications for Rexulti, as mentioned by the doctor.</li></ul>Areas of improvements:<ul><li>The sales representative should engage more actively by asking detailed questions that delve into the specific benefits and data backing Rexulti.</li><li>Providing more detailed, targeted information aligned with the brand strategy would be beneficial.</li><li>The sales representative should aim to balance the conversation better, seeking more information but also sharing essential data points.</li></ul>`;
  return (
    <div className="bg-white shadow-[0px_4px_24px_0px_rgba(203,90,90,0.05)] p-6 rounded-[14px]">
      <div className="font-bold text-xl text-black">AI Overview</div>
      <div className="min-h-[350px] h-[20vh] overflow-auto flex flex-col gap-5">
        <div
          className="font-bold text-xl text-black capitalize [&_ul]:font-normal [&_ul]:normal-case [&_ul]:text-black/60 [&_ul]:text-[15px] [&_ul]:!list-disc [&_ul]:p-[revert] [&_ul]:m-[revert]"
          dangerouslySetInnerHTML={{
            __html: report.summary || report?.report?.summary,
          }}
        />
      </div>
    </div>
  );
}
