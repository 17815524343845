import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as Logo } from "../../assets/icons/animation-logo.svg";
import { ActionButtons } from "./ActionButtons";
import { usePraxisCall } from "../praxis-play/context/praxiscall-context";
import { AudioPlayer } from "./components/AudioPlayer";
import ClockTimer from "./components/ClockTimer";
import { useAssemblyAI } from "./services/useAssemblyAI";
import * as RecordRTC from "recordrtc";
import { PraxisLoading } from "./PraxisLoading";
import { api } from "../../services/api";
import { useApp } from "../../services/app-context";
import { uploadAudioToS3, uploadFullVideoToS3, uploadVideoToS3 } from "./utils";
import { ShowToast } from "../../services/toast";
import toast from "react-hot-toast";
import { useUser } from "../../services/user-context";
import Lottie from "lottie-react";
import * as animationData from "../../assets/loading-dots.json";
import { useUserSpeakingDetector } from "./components/userSpeakingDetector";
import useVoiceActivityDetector from "./components/useVoiceActivityDetector";

const audioPlayer = new AudioPlayer();

export function PraxisCall() {
  const { tenantProfile } = useApp();
  const tenantProfileName = tenantProfile?.user_pool_name;
  const recordingCountRef = useRef(1);
  const recorderRef = useRef<any>(null);
  const { userInfo } = useUser() as any;
  const {
    callDetails,
    onDisconnect,
    transcriberToken,
    setLoading,
    setTranscripts,
  } = usePraxisCall();
  const [isConnected, setIsConnected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const isUploadingRef = useRef(false);
  const isConnectedRef = useRef(false);
  const incomingAudioChunks = useRef<any[]>([]);
  const outgoingAudioChunks = useRef<any[]>([]);
  const outgoingMediaRecorderRef = useRef<any>(null);
  const incomingMediaRecorderRef = useRef<any>(null);

  // const { realtimeTranscriber } = useAssemblyAI({
  //   token: transcriberToken,
  //   onConnect: () => {
  //     setIsConnected(true);
  //     isConnectedRef.current = true;
  //     if (callDetails?.isVideoRecording) {
  //       startRecording();
  //       startAudioRecording();
  //     }
  //     isConnectedRef.current = true;
  //   },
  //   onTranscript: (transcript) => {
  //     if (
  //       !isUploadingRef.current &&
  //       !audioPlayer.isPlaying &&
  //       callDetails?.id &&
  //       transcript?.trim()?.length > 0
  //     ) {
  //       setIsUploading(true);
  //       isUploadingRef.current = true;
  //       api
  //         .chatStream({ query: transcript, callId: callDetails.id })
  //         .then(async (response) => {
  //           setIsUploading(false);
  //           isUploadingRef.current = false;
  //           if (response) {
  //             monitorVoiceIntensity();
  //             const binaryString = atob(JSON.parse(response));
  //             const len = binaryString.length;
  //             const bytes = new Uint8Array(len);
  //             for (let i = 0; i < len; i++) {
  //               bytes[i] = binaryString.charCodeAt(i);
  //             }
  //             try {
  //               audioPlayer.enqueueBuffer(bytes.buffer);
  //             } catch (error) {
  //               console.error("Failed to decode audio:", error);
  //             }
  //           }
  //         })
  //         .catch((error) => {
  //           console.log("Error uploading transcript:", error);
  //           setIsUploading(false);
  //           isUploadingRef.current = false;
  //         });
  //     }
  //   },
  // });
  const [voiceIntensity, setVoiceIntensity] = useState(0);
  const [userVoiceIntensity, setUserVoiceIntensity] = useState(0);

  const audioContextRef = useRef<any>(null);
  const analyserRef = useRef<any>(null);
  const microphoneRef = useRef<any>(null);
  const volumeMonitorIntervalRef = useRef<any>(null);
  const screenStreamRef = useRef<MediaStream | null>(null);
  const webcamStreamRef = useRef<MediaStream | null>(null);
  const webcamVideoRef = useRef<HTMLVideoElement | null>(null);

  const currentStream = useRef<any | null>(null);
  const currentConnection = useRef<any | null>(null);
  const streamRef = useRef<any>();
  const dataChannel = useRef<any>(null);

  const { isSpeaking } = useUserSpeakingDetector({
    stream: streamRef.current,
    onSpeakingChange: (state) => {
      // if (state === "stopped_speaking") {
      //   setIsUploading(true);
      // } else {
      //   setIsUploading(false);
      // }
    },
  });

  // const createRecorder = useCallback(() => {
  //   if (!realtimeTranscriber || !streamRef.current) return;
  //   if (recorderRef.current) return;

  //   recorderRef.current = new RecordRTC(streamRef.current, {
  //     type: "audio",
  //     mimeType: "audio/webm;codecs=pcm",
  //     recorderType: RecordRTC.StereoAudioRecorder,
  //     timeSlice: 250,
  //     desiredSampRate: 16000,
  //     numberOfAudioChannels: 1,
  //     bufferSize: 4096,
  //     audioBitsPerSecond: 128000,
  //     ondataavailable: (blob) => {
  //       if (isConnectedRef.current) {
  //         try {
  //           realtimeTranscriber?.sendAudio(blob);
  //         } catch (e) {}
  //       }
  //     },
  //   });

  //   recorderRef.current?.startRecording();
  // }, [realtimeTranscriber]);

  // useEffect(() => {
  //   if (isConnected && realtimeTranscriber?.sendAudio) {
  //     createRecorder();
  //   }
  // }, [isConnected, realtimeTranscriber, createRecorder]);

  const destroyStream = (stream: MediaStream) => {
    stream.getTracks().forEach((track) => {
      track.stop();
      track.enabled = false;
      stream.removeTrack(track);
    });
  };

  // useEffect(() => {
  //   const setupAudioContext = async () => {
  //     try {
  //       audioPlayer.ensureAudioContext();
  //       const stream = await navigator.mediaDevices.getUserMedia({
  //         audio: {
  //           echoCancellation: true,
  //           noiseSuppression: true,
  //         },
  //       });
  //       // @ts-ignore
  //       if (!window.stream) {
  //         // @ts-ignore
  //         window.stream = [stream];
  //       } else {
  //         // @ts-ignore
  //         window.stream.push(stream);
  //       }
  //       streamRef.current = stream;
  //       createRecorder();
  //       audioContextRef.currentaudioContextRef.current = new (window.AudioContext ||
  //         (window as any).webkitAudioContext)();
  //       microphoneRef.current =
  //         audioContextRef.current.createMediaStreamSource(stream);
  //       analyserRef.current = audioContextRef.current.createAnalyser();
  //       microphoneRef.current.connect(analyserRef.current);
  //       analyserRef.current.fftSize = 256;

  //       // Buffer to store frequency data
  //       const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);

  //       const captureVoiceIntensity = () => {
  //         analyserRef.current.getByteFrequencyData(dataArray);
  //         let sum = 0;
  //         dataArray.forEach((value) => (sum += value));
  //         const average = sum / dataArray.length;
  //         setUserVoiceIntensity(average);
  //         requestAnimationFrame(captureVoiceIntensity);
  //       };

  //       captureVoiceIntensity();
  //     } catch (error: any) {
  //       if (error.name === "NotAllowedError") {
  //         toast.dismiss();
  //         ShowToast({
  //           message:
  //             "Please allow microphone access to continue. Without microphone you won't be able to proceed.",
  //           type: "error",
  //         });
  //       } else {
  //         console.error("Error setting up audio context:", error);
  //       }
  //     }
  //   };

  //   setupAudioContext();

  //   return () => {};
  // }, [createRecorder]);

  const handleClose = async () => {
    if (callDetails?.isVideoRecording) {
      stopRecording();
    }
    // stopAudioRecording();
    // if (audioPlayer.source) {
    //   audioPlayer.source.disconnect();
    //   audioPlayer.source.stop();
    // }
    // audioPlayer.audioContext.suspend();
    // if (recorderRef.current) {
    //   recorderRef.current.destroy();
    // }
    // @ts-ignore
    window.stream?.forEach((stream) => {
      destroyStream(stream);
    });
    // if (streamRef.current) {
    //   streamRef.current.getTracks().forEach((track) => {
    //     track.stop();
    //     streamRef.current.removeTrack(track);
    //   });
    //   streamRef.current = null;
    // }
    // if not video recording, disconnect from call.
    if (!callDetails?.isVideoRecording) {
      await onDisconnect();
    }
  };

  // const monitorVoiceIntensity = useCallback(() => {
  //   const captureVoiceIntensity = () => {
  //     if (!audioPlayer.isPlaying) {
  //       setVoiceIntensity(0);
  //       return;
  //     }
  //     const dataArray = new Uint8Array(audioPlayer.analyser.frequencyBinCount);

  //     audioPlayer.analyser.getByteFrequencyData(dataArray);
  //     let sum = 0;
  //     audioPlayer.dataArray.forEach((value) => (sum += value));
  //     const average = sum / dataArray.length;
  //     setVoiceIntensity(Math.min(average, 100) * 100000);
  //     // requestAnimationFrame(captureVoiceIntensity);
  //   };

  //   volumeMonitorIntervalRef.current = setInterval(captureVoiceIntensity, 100);
  //   captureVoiceIntensity();
  // }, []);

  const stopMonitoringVoiceIntensity = useCallback(() => {
    if (volumeMonitorIntervalRef.current) {
      clearInterval(volumeMonitorIntervalRef.current);
      setUserVoiceIntensity(0);
      setVoiceIntensity(0);
      volumeMonitorIntervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    document.addEventListener("playbackFinished", stopMonitoringVoiceIntensity);
    return () => {
      document.removeEventListener(
        "playbackFinished",
        stopMonitoringVoiceIntensity
      );
    };
  }, [stopMonitoringVoiceIntensity]);

  const mediaRecorderRef = useRef<any>(null);
  const audioMediaRecorderRef = useRef<any>(null);
  const videoRef = useRef<any>(null);

  const startAudioRecording = async () => {
    try {
      // Capture microphone audio separately
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
      });
      // @ts-ignore
      window.stream.push(audioStream);

      // Combine both screen (with system audio) and microphone audio
      const combinedStream = new MediaStream([...audioStream.getAudioTracks()]);

      // Assign combined stream to video element (for preview)
      videoRef.current.srcObject = combinedStream;

      // Start recording with MediaRecorder
      const mediaRecorder = new MediaRecorder(combinedStream, {
        mimeType: "audio/webm;codecs=pcm",
      });
      audioMediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          const blob = new Blob([event.data], { type: "audio/webm" });
          const fileName = `${callDetails?.id}_user_${recordingCountRef.current}.webm`;
          recordingCountRef.current++;
          try {
            if (callDetails?.id) {
              uploadAudioToS3(
                blob,
                fileName,
                tenantProfileName,
                userInfo?.["cognito:username"],
                callDetails?.id
              );
            }
          } catch (err) {
            console.error("Error uploading file:", err);
          }
        }
      };

      mediaRecorder.start();
    } catch (err) {
      console.error("Error starting recording:", err);
    }
  };

  const startRecording = async () => {
    try {
      // Capture screen stream
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          // @ts-ignore
          displaySurface: "browser",
        },
        // @ts-ignore
        preferCurrentTab: true,
        // @ts-ignore
        selfBrowserSurface: "include",
        audio: false,
      });
      screenStreamRef.current = screenStream;

      // Capture webcam stream
      const webcamStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
      });
      webcamStreamRef.current = webcamStream;

      // Attach webcam stream to PiP video element
      if (webcamVideoRef.current) {
        webcamVideoRef.current.srcObject = webcamStream;
        await webcamVideoRef.current.play();
      }
      // @ts-ignore
      if (!window.stream) {
        // @ts-ignore
        window.stream = [];
      }
      // @ts-ignore
      window.stream.push(screenStream);
      // @ts-ignore
      window.stream.push(webcamStream);

      // Combine both screen (with system audio) and microphone audio
      const combinedStream = new MediaStream([
        ...screenStream.getTracks(),
        ...webcamStream.getTracks(),
      ]);

      // Assign combined stream to video element (for preview)
      // videoRef.current.srcObject = combinedStream;

      // Start recording with MediaRecorder
      const mediaRecorder = new MediaRecorder(combinedStream, {
        mimeType: "video/webm;codecs=pcm",
      });
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          const blob = new Blob([event.data], { type: "video/webm" });
          const fileName = `${callDetails?.id}_user_${recordingCountRef.current}.webm`;
          recordingCountRef.current++;

          try {
            // uploadVideoToS3(
            //   blob,
            //   fileName,
            //   tenantProfileName,
            //   callDetails?.isCertificationMode
            //     ? "certification_recordings"
            //     : "session_recordings"
            // );

            if (callDetails?.id) {
              setLoading(true);
              uploadFullVideoToS3(
                blob,
                fileName,
                tenantProfileName,
                userInfo?.["cognito:username"],
                callDetails?.id
              )
                .then(async () => {
                  // once video is uploaded, disconnect from call
                  await onDisconnect();
                })
                .catch((err) => {
                  console.error("Error uploading file:", err);
                  setLoading(false);
                });
            }
          } catch (err) {
            console.error("Error uploading file:", err);
          }
        }
      };

      mediaRecorder.start();
    } catch (err) {
      console.error("Error starting recording:", err);
    }
  };

  const restartRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "recording" &&
      callDetails?.isVideoRecording
    ) {
      mediaRecorderRef.current.start();
    }
  };

  const stopRecording = async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    } else {
      await onDisconnect();
    }
  };

  const stopAudioRecording = () => {
    if (audioMediaRecorderRef.current) {
      audioMediaRecorderRef.current.stop();
    }
  };

  const restartAudioRecording = () => {
    if (
      audioMediaRecorderRef.current &&
      audioMediaRecorderRef.current.state !== "recording" &&
      callDetails?.isVideoRecording
    ) {
      audioMediaRecorderRef.current.start();
    }
  };

  useEffect(() => {
    if (!audioPlayer.volumeMonitorInterval && callDetails?.isVideoRecording) {
      restartAudioRecording();
    }
  }, [audioPlayer.volumeMonitorInterval, callDetails?.isVideoRecording]);

  // Function to monitor voice intensity
  function monitorVoiceIntensity(remoteStream) {
    const audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    const source = audioContext.createMediaStreamSource(remoteStream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;

    source.connect(analyser);

    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    function calculateIntensity() {
      analyser.getByteTimeDomainData(dataArray);

      let sum = 0;
      for (let i = 0; i < dataArray.length; i++) {
        const normalizedValue = (dataArray[i] - 128) / 128; // Normalize to [-1, 1]
        sum += normalizedValue ** 2;
      }
      const rms = Math.sqrt(sum / dataArray.length);
      return rms;
    }

    function monitor() {
      const intensity = calculateIntensity();
      setVoiceIntensity(intensity);
      requestAnimationFrame(monitor);
    }

    monitor();
  }

  async function fetchPersonalityContext(args) {
    try {
      console.log("Fetching personality context...");
      const response = await api.fetchPersonalityContext({
        sentence: args.sentence,
        personality: (args.personalities || [])?.join(","),
      });
      const data = response.data;
      console.log("Personality Context API response:", data);

      // Create the event to send back to OpenAI
      const event = {
        type: "conversation.item.create",
        item: {
          type: "function_call_output",
          call_id: args.call_id,
          output: JSON.stringify(data),
        },
      };

      // Send function results
      dataChannel.current.send(JSON.stringify(event));
      console.log("Event sent:", event);

      // Trigger a new response from the model
      const triggerResponse = {
        type: "response.create",
      };
      dataChannel.current.send(JSON.stringify(triggerResponse));
      console.log("Triggered new response");

      return data;
    } catch (error) {
      console.error("Error fetching personality context:", error);
    }
  }

  async function init({
    clientSecret,
    isVideoRecording,
  }: {
    clientSecret: string;
    isVideoRecording?: boolean;
  }) {
    if (currentConnection.current) {
      console.log("already connected");
      return;
    }
    if (isVideoRecording) {
      startRecording();
    }
    const EPHEMERAL_KEY = clientSecret;

    // Create a peer connection
    const pc = new RTCPeerConnection();
    currentConnection.current = pc;
    // Set up to play remote audio from the model
    const audioEl = document.createElement("audio");
    audioEl.autoplay = true;
    pc.ontrack = (e) => {
      const remoteStream = e.streams[0];
      audioEl.srcObject = remoteStream;

      // Set up MediaRecorder just for logging
      const mediaRecorder = new MediaRecorder(e.streams[0]);
      incomingMediaRecorderRef.current = mediaRecorder;
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          console.log("Audio chunk received:", event.data);
          console.log("Chunk size:", event.data.size, "bytes");
          console.log("Chunk type:", event.data.type);
          incomingAudioChunks.current.push(event.data);
        }
      };

      // Request data every 1 second
      mediaRecorder.start(1000);

      // Calculate voice intensity from the remote stream
      monitorVoiceIntensity(remoteStream);
    };

    // Add local audio track for microphone input in the browser
    const ms = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    currentStream.current = ms;
    pc.addTrack(ms.getTracks()[0]);

    const outgoingMediaRecorder = new MediaRecorder(ms, {
      mimeType: "audio/webm;codecs=pcm",
    });
    outgoingMediaRecorderRef.current = outgoingMediaRecorder;
    outgoingMediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        console.log("Outgoing audio chunk:", event.data);
        console.log("Outgoing chunk size:", event.data.size, "bytes");
        console.log("Outgoing chunk type:", event.data.type);
        if (!outgoingAudioChunks.current) {
          outgoingAudioChunks.current = [];
        }
        outgoingAudioChunks.current.push(event.data);
      }
    };
    outgoingMediaRecorder.start(1000);

    // Set up data channel for sending and receiving events
    const dc = pc.createDataChannel("oai-events");
    dataChannel.current = dc;
    dc.addEventListener("message", (e) => {
      // Realtime server events appear here!
      const data = JSON.parse(e.data);
      console.log(data, "data");
      // add transcript to state
      if (
        data.type === "conversation.item.input_audio_transcription.completed"
      ) {
        const transcript = data.transcript;
        if (transcript?.trim()?.length > 0) {
          setTranscripts((prev) => [
            ...prev,
            { role: "user", content: transcript },
          ]);
        }
      } else if (data.type === "response.done") {
        const message = data.response?.output?.[0]?.content?.[0]?.transcript;

        if (message?.trim()?.length > 0) {
          setTranscripts((prev) => [
            ...prev,
            { role: "assistant", content: message },
          ]);
        }
      } else if (data.type === "response.function_call_arguments.done") {
        const args = JSON.parse(data.arguments);
        console.log(args, "args");
        if (data.name === "get_personality_context") {
          fetchPersonalityContext({ ...args, call_id: data.call_id });
        }
      }
      if (data.type === "session.created") {
        setIsConnected(true);
      }
      if (data.type === "conversation.item.created") {
        setIsUploading(true);
      }
      if (data.type === "response.content_part.added") {
        setIsUploading(false);
      }
    });

    // Start the session using the Session Description Protocol (SDP)
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    const baseUrl = "https://api.openai.com/v1/realtime";
    const model = "gpt-4o-realtime-preview-2024-12-17";
    const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
      method: "POST",
      body: offer.sdp,
      headers: {
        Authorization: `Bearer ${EPHEMERAL_KEY}`,
        "Content-Type": "application/sdp",
      },
    });

    const answer: any = {
      type: "answer",
      sdp: await sdpResponse.text(),
    };
    await pc.setRemoteDescription(answer);
  }

  function stopSession() {
    if (currentStream.current) {
      currentStream.current.getTracks().forEach((track) => track.stop());
      currentStream.current = null;
    }

    if (currentConnection.current) {
      currentConnection.current.close();
      currentConnection.current = null;
    }

    if (audioContextRef.current) {
      audioContextRef.current?.suspend?.();
    }

    // outgoingMediaRecorder
    if (
      outgoingMediaRecorderRef.current &&
      outgoingMediaRecorderRef.current.state !== "inactive"
    ) {
      outgoingMediaRecorderRef.current.stop();
      const audioBlob = new Blob(outgoingAudioChunks.current, {
        type: "audio/webm;codecs=opus",
      });
      console.log("audioBlob", audioBlob);
      // const userDownloadLink = document.createElement("a");
      // userDownloadLink.href = URL.createObjectURL(audioBlob);
      // userDownloadLink.download = "user-recording.webm";
      // userDownloadLink.click();
      // URL.revokeObjectURL(userDownloadLink.href);
    }

    // incomingMediaRecorder
    if (
      incomingMediaRecorderRef.current &&
      incomingMediaRecorderRef.current.state !== "inactive"
    ) {
      incomingMediaRecorderRef.current.stop();
      const audioBlob = new Blob(incomingAudioChunks.current, {
        type: "audio/webm;codecs=opus",
      });
      console.log("audioBlob", audioBlob);
      // const assistantDownloadLink = document.createElement("a");
      // assistantDownloadLink.href = URL.createObjectURL(audioBlob);
      // assistantDownloadLink.download = "assistant-recording.webm";
      // assistantDownloadLink.click();
      // URL.revokeObjectURL(assistantDownloadLink.href);
    }

    handleClose();
    // document.getElementById("stopButton").disabled = true;
    // document.getElementById("status").textContent = "Session stopped";
  }

  async function startSession({ clientSecret }: { clientSecret: string }) {
    init({ clientSecret, isVideoRecording: callDetails?.isVideoRecording })
      .then(() => {
        console.log("Session started");
      })
      .catch((error) => {
        console.error("Session error:", error, error.message);
        stopSession();
      });
  }

  useEffect(() => {
    if (callDetails?.clientSecret) {
      startSession({ clientSecret: callDetails?.clientSecret });
    }
  }, [callDetails?.clientSecret]);

  return (
    <div className="fixed z-[9] top-0 bottom-0 left-0 right-0">
      <div className="w-full h-full left-0 top-0 absolute bg-zinc-300 rounded-xl" />
      <div className="w-60 h-full left-[1341.79px] top-[-15.71px] absolute bg-gradient-to-b from-neutral-300 via-gray-300 to-gray-300" />
      <div className="w-64 h-full left-[-8.16px] top-[-34.27px] absolute bg-gradient-to-b from-neutral-300 via-gray-300 to-gray-300" />
      <div className="w-full h-full left-0 top-0 absolute bg-callScreen rounded-xl" />
      <div className="absolute top-0 bottom-0 h-full w-full">
        <button
          type="button"
          onClick={() => {
            console.log("stopSession", callDetails?.isVideoRecording);
            stopSession();
          }}
          className="w-10 h-10 p-2 bg-white rounded-3xl justify-start items-start gap-2 flex absolute right-3.5 top-3.5"
        >
          <CloseIcon className="w-6 h-6" />
        </button>
        {isConnected && <ClockTimer />}
        <ActionButtons
          onDisconnect={() => {
            stopSession();
          }}
        />
        <div
          className="ai-interaction-container"
          style={{
            background:
              "linear-gradient(180deg, #DEDCDB 0%, #DCDAD9 20%, #DCD8D7 42.5%, #D8D6D4 52.5%, #D6D2D1 60%, #D5D1CF 80%, #D3CECC 100%)",
          }}
        >
          <div
            className={`relative animation-wrapper ${
              isUploading ? "loading" : "ready"
            }`}
          >
            <div className="ai-dots">
              <Lottie
                animationData={animationData}
                loop={true}
                className={`w-[175px] h-[175px] loading-dots absolute left-0`}
              />
            </div>
            <div className="ai-logo">
              <div
                className={`ai-circle`}
                style={{
                  // increase drop shadow area when speaking
                  filter:
                    "drop-shadow(0px 0px 3.039px #E79AAF) drop-shadow(0px 0px 6.077px #E79AAF) drop-shadow(0px 0px 24px #7F2F44)",
                  backgroundColor: "#F0ECEC",
                  // transform: `scale(${voiceIntensity + 1})`,
                  boxShadow: voiceIntensity
                    ? `0px 0px 15.216px 9.47px rgba(173, 93, 57, 0.27) inset, 0 0 0 ${
                        voiceIntensity * 1000 < 20
                          ? "0"
                          : voiceIntensity * 1000 > 50
                          ? 50
                          : voiceIntensity * 1000
                      }px rgba(173, 93, 57, ${Math.min(
                        0.27,
                        (voiceIntensity * 1000) / 50 > 0.75
                          ? 0.75
                          : (voiceIntensity * 1000) / 50
                      )})`
                    : "0px 0px 15.216px 9.47px rgba(173, 93, 57, 0.27) inset",
                  // slow down scaling and box shadow animation
                  transition: "transform 0.01s ease, box-shadow 0.2s ease",
                }}
              >
                <div className="ai-logo ">
                  <Logo />
                </div>
              </div>

              {/* {isUploading && (
                  <div className="rings absolute top-0 left-0 w-full h-full">
                    <div className="ring-layer"></div>
                    <div className="ring-layer"></div>
                    <div className="ring-layer"></div>
                    <div className="ring-layer"></div>
                    <div className="ring-layer"></div>
                    <div className="ring-layer"></div>
                    <div className="ring-layer"></div>
                  </div>
                )} */}
            </div>
          </div>
        </div>
      </div>
      {!isConnected && <PraxisLoading />}
      {/* PiP Video for Webcam */}
      {callDetails?.isVideoRecording && (
        <video
          ref={webcamVideoRef}
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            width: "200px",
            height: "150px",
            border: "2px solid white",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            backgroundColor: "black",
          }}
          muted
          playsInline
        ></video>
      )}
    </div>
  );
}
