import { Card } from "../../../components/Card";
import { ReportInsights } from "../../../constant";
import { CallInsight } from "../../praxis-call/components/report-tabs/Performance";

const metrics = [
  {
    name: ReportInsights.languageClarity,
    percentage: 80,
  },
  {
    name: ReportInsights.vocabulary,
    percentage: 60,
  },
  {
    name: ReportInsights.tone,
    percentage: 80,
  },
  {
    name: ReportInsights.brandStrategy,
    percentage: 40,
  },
  {
    name: ReportInsights.compliancePercentage,
    percentage: 40,
  },
  {
    name: ReportInsights.speakListenRatio,
    percentage: 80,
  },
  {
    name: ReportInsights.openClosedQuestions,
    percentage: 80,
  },
  {
    name: ReportInsights.questionTechniques,
    percentage: 80,
  },
];

console.log(ReportInsights);

export function PraxisproInsights() {
  return (
    <Card>
      <div className="flex flex-col mb-[18px] gap-4">
        <div className="text-xl font-bold">Praxis Play Sessions</div>
        <p>
          Explore the insights into your exceptional performance during the
          Praxis Play Sessions by delving into each of the meticulously crafted
          metrics. Click on each metric to uncover a detailed analysis of your
          proficiency and discover valuable information that highlights your
          strengths and areas for potential growth. Your journey towards
          excellence awaits – let's dive in!
        </p>
      </div>
      <div className="grid grid-cols-2 md:flex flex-wrap items-center gap-4">
        {metrics.map((metric) => (
          <CallInsight
            title={ReportInsights[metric.name] || metric.name}
            percentage={metric.percentage}
          />
        ))}
      </div>
    </Card>
  );
}
