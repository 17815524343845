import { DataTable } from "./data-table";
import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { certificationStatus } from "../../../constant";
import { ImageView } from "../../../components/Image";
import { ReactComponent as StarIcon } from "../../../assets/icons/star.svg";
import { getInitial } from "../../../services/utils";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../services/api";
import { usePraxisCall } from "../../praxis-play/context/praxiscall-context";
import { useCertification } from "../context/certification-context";

const filterData = (
  data: any[],
  filter: any,
  type: "user-dashboard" | "manager-dashboard" | "manager-user-table"
) => {
  console.log("filter", filter, data);
  let filteredData = [...data];
  const isManagerDashboard = type === "manager-dashboard";
  if (filter.search) {
    filteredData = filteredData.filter((item) => {
      // if manager dashboard then check userName too
      if (isManagerDashboard) {
        const searchTerm = filter.search.toLowerCase();
        const certName =
          item?.certification?.certificationName?.toLowerCase() ?? "";
        const firstName = item?.user?.firstName?.toLowerCase() ?? "";
        const lastName = item?.user?.lastName?.toLowerCase() ?? "";

        return (
          certName.includes(searchTerm) ||
          firstName.includes(searchTerm) ||
          lastName.includes(searchTerm)
        );
      }

      const searchTerm = filter.search.toLowerCase();
      const certName =
        item?.certification?.certificationName?.toLowerCase() ?? "";
      return certName.includes(searchTerm);
    });
  }
  if (filter.sortBy && filter.sortOrder) {
    filteredData = filteredData.sort((a, b) => {
      if (filter.sortBy === "createdAt") {
        // date filter
        const aDate = moment(a.createdAt).utc().format("YYYY-MM-DD");
        const bDate = moment(b.createdAt).utc().format("YYYY-MM-DD");
        const before =
          filter.sortOrder === "desc"
            ? moment(bDate).isBefore(aDate)
            : moment(aDate).isBefore(bDate);
        return before ? -1 : 1;
      }
      return filter.sortOrder === "desc"
        ? b[filter.sortBy] - a[filter.sortBy]
        : a[filter.sortBy] - b[filter.sortBy];
    });
  }
  console.log("filteredData", filteredData);
  return filteredData;
};

export function StatusCell({ status }: { status: string }) {
  const statusData =
    certificationStatus[status as keyof typeof certificationStatus] ||
    certificationStatus.NotStarted;
  return (
    <div
      className={`rounded-md w-[102px] h-[37px] flex items-center justify-center text-[13px]`}
      style={{
        backgroundColor: statusData?.bgColor,
        color: statusData?.textColor,
      }}
    >
      {statusData?.label}
    </div>
  );
}

function PraxisScoreCell({ score }: { score: number }) {
  const status = !score ? "N/A" : score < 3 ? "Fail" : "Pass";
  const statusData = certificationStatus[
    status as keyof typeof certificationStatus
  ] || {
    label: "N/A",
    bgColor: "transparent",
    textColor: "#C6C6C6",
  };
  return (
    <div
      className={`rounded-md gap-2 w-[102px] h-[37px] flex items-center justify-center text-[13px]`}
      style={{
        backgroundColor: statusData.bgColor,
        color: statusData.textColor,
      }}
    >
      {score && <StarIcon className="w-4 h-4" />}
      {score || "N/A"}
    </div>
  );
}

const getColumns = (
  type: "manager-dashboard" | "manager-user-table" | "user-dashboard",
  data: any[],
  { onAction }: { onAction: (row: any) => void }
) => {
  const commonColumns = [
    {
      accessorKey: "finalStatus",
      header: ({ column }) => {
        return <div className="p-0">Status</div>;
      },
      cell: ({ row }) => {
        return <StatusCell status={row.original.finalStatus} />;
      },
    },
    {
      accessorKey: "finalScore",
      header: "Praxis Score",
      cell: ({ row }) => {
        return <PraxisScoreCell score={row.original.finalScore} />;
      },
    },
    {
      accessorKey: "attempts",
      header: "Attempts",
      cell: ({ row }) => {
        return `${row.original.attemptCount} / ${
          row.original.certification?.certificationAttemptsAllowed || 0
        }`;
      },
    },
    {
      accessorKey: "Date Created",
      header: ({ column }) => {
        return <div className="p-0">Date Created</div>;
      },
      cell: ({ row }) => {
        return (
          <span>
            {moment(row.original.createdAt).utc().format("MMM DD, YYYY")}
          </span>
        );
      },
    },

    {
      id: "actions",
      header: () => {
        return <div className="w-full flex justify-end">Session</div>;
      },
      cell: ({ row }) => {
        return (
          <div className="w-full flex justify-end">
            <button
              onClick={() => {
                onAction(row.original);
              }}
              className="text-sm text-center text-[#242424] hover:text-primary2"
            >
              {type === "user-dashboard" ? "Take it" : "Review"}
            </button>
          </div>
        );
      },
    },
  ];
  if (type === "manager-dashboard") {
    return [
      {
        accessorKey: "userId",
        header: ({ column }) => {
          return (
            <div
              // onClick={() => {
              //   if (sortBy === "name") {
              //     setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
              //   } else {
              //     setSortBy("name");
              //     setSortOrder("ASC");
              //   }
              // }}
              className="p-0"
            >
              Team Member
              {/* {sortBy === "name" ? (
                sortOrder === "ASC" ? (
                  <ArrowUpIcon className="w-4 h-4 rotate-[180deg] ml-2" />
                ) : (
                  <ArrowUpIcon className="w-4 h-4 ml-2" />
                )
              ) : (
                <ArrowUpDownIcon className="w-4 h-4 ml-2" />
              )} */}
            </div>
          );
        },
        cell: ({ row }) => {
          const record = row.original;
          return (
            <Link
              to={`/certifications/user/${row.original?.user?.id}`}
              className="flex items-center gap-2"
            >
              {/* <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                <img src={row.original.userImage} alt="user" />
              </div> */}
              {row.original.user?.profileImg ? (
                <ImageView
                  className="w-8 h-8 rounded-full object-cover"
                  src={row.original.user?.profileImg}
                  alt="user"
                />
              ) : (
                <div className="bg-primary w-8 h-8 rounded-full text-white text-lg flex justify-center items-center font-semibold">
                  {getInitial(
                    row.original.user?.firstName,
                    row.original.user?.lastName
                  )}
                </div>
              )}
              <div>
                {row.original.user?.firstName} {row.original.user?.lastName}
              </div>
            </Link>
          );
        },
      },
      {
        accessorKey: "certificationName",
        header: ({ column }) => {
          return <div className="p-0">Certifications</div>;
        },
        cell: ({ row }) => {
          return <div>{row.original.certification?.certificationName}</div>;
        },
      },
      ...commonColumns,
    ];
  }
  return [
    {
      accessorKey: "certificationName",
      header: ({ column }) => {
        return <div className="p-0">Certifications ({data.length})</div>;
      },
      cell: ({ row }) => {
        return <div>{row.original.certification?.certificationName}</div>;
      },
    },
    ...commonColumns,
  ];
};

type User = {
  id: string;
  name: string;
  email: string;
  joinedOn: string;
  tier: string;
  totalSellers: number;
  status: string;
  statusText: string;
  statusDescription: string;
  totalSpend: string;
  stripeCustomerId: string;
  subscription: any;
  openDrawer: (row: any) => void;
  deleteModal: (row: any) => void;
  deactivateModal: (row: any) => void;
};

export function CertificationTable({
  type,
  userId,
  filter,
}: {
  type: "manager-dashboard" | "manager-user-table" | "user-dashboard";
  userId?: string;
  filter?: any;
}) {
  const navigate = useNavigate();
  const { setAssignment } = usePraxisCall();
  const {
    certifications: certificationList,
    users,
    setSelectedCertificationAssignment,
  } = useCertification();

  const {
    data: certifications,
    isLoading: isLoadingCertifications,
    refetch,
  } = useQuery({
    queryKey: [`certifications-assignments-${type}`, userId],
    queryFn: () => api.getAssignments({ userId }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const data = useMemo(() => {
    const assignments = (certifications?.data?.assignments || []).map(
      (assignment) => {
        const certification = certificationList.find(
          (certification) => certification.id === assignment.certificationId
        );
        const user = users.find((user) => user.id === assignment.userId);
        return {
          ...assignment,
          certificationName: certification?.name,
          certification,
          user,
        };
      }
    );
    return assignments;
  }, [certifications, certificationList]);

  const columns: ColumnDef<User>[] = getColumns(type, data, {
    onAction: (row) => {
      if (type === "user-dashboard") {
        setAssignment(row);
        navigate(`/praxisplay/role-play`);
      } else {
        setSelectedCertificationAssignment(row);
        navigate(`/certifications/review/${row?.id}`);
      }
    },
  });

  return (
    <div>
      <div className="flex flex-col gap-5">
        <DataTable
          columns={columns}
          onRowClick={(row) => {
            // setSelectedRow(row);
          }}
          data={filterData(data, filter, type)}
          isLoading={isLoadingCertifications}
        />
      </div>
    </div>
  );
}
