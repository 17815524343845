import React, { useEffect } from "react";
import { ShowToast } from "./toast";
import { getUser, setToken } from "./utils";
import { api } from "./api";
import { useApp } from "./app-context";

export const UserContext = React.createContext({});

export function UserProvider({ children }) {
  const { userSession } = useApp();
  const [userInfo, setUserInfo] = React.useState({});
  const [userInfoLoading, setUserInfoLoading] = React.useState(false);
  const user = getUser();
  const fetchUser = async (userId: string) => {
    try {
      setUserInfoLoading(true);
      // TODO: get user attributes from api
      // const userAttributes = await api.getUserAttributes(userId);
      // console.log("userAttributes", userAttributes);
      const userResponse = await api.searchUsers();
      const user = userResponse?.users?.find((user) => user.id === userId);
      setUserInfoLoading(false);
      return user;
    } catch (error) {
      setUserInfoLoading(false);
      ShowToast({
        message: "Something went wrong while fetching user info",
        type: "error",
      });
      return {};
    }
  };
  const values = {
    userInfo,
    setUserInfo,
    fetchUser,
    loading: userInfoLoading,
  };
  useEffect(() => {
    if (userSession?.idToken) {
      setToken(userSession?.idToken?.jwtToken);

      const userId = userSession?.idToken?.payload["cognito:username"];
      if (userId) {
        fetchUser(userId).then((user) => {
          setUserInfo({
            ...(userSession?.idToken?.payload || {}),
            ...user,
            //TODO: dummy role
            // role: userRoles.Person,
          });
        });
      }
    }
  }, [userSession]);
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
