import { Card } from "../../../components/Card";
import { Rating } from "../../praxis-call/components/Rating";

export const PraxisScoreCard = () => {
  return (
    <Card className="!px-0 !py-6 flex-1 !w-full md:min-w-[350px] lg:min-h-[220px] lg:max-h-[220px] lg:overflow-auto">
      <div className="flex px-6 justify-between items-center mb-5 gap-6 lg:gap-11">
        <div className="text-xl font-bold">Praxis Score</div>
        <div>
          <button className="uppercase text-primary2 hover:text-primary2/80 text-sm font-semibold cursor-pointer">
            View My Feedback
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-5 px-6">
        <Rating value={4.5} showMax={false} />
        <p className="text-sm text-text2">
          This is your cumulative PraxisPlay performance score! View your
          AI-Summarized feedback for insights on enhancing your skills in
          preparation for future sessions.
        </p>
      </div>
    </Card>
  );
};
