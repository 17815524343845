import { MoreActionView } from "./MoreActionView";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { getInitial } from "../../../../services/utils";
import { ImageView } from "../../../../components/Image";

export const UserProfileView = ({ user }: { user: any }) => {
  return (
    <div className="flex justify-between items-start">
      <div className="flex items-start gap-4">
        <div className="w-[70px] h-[70px] rounded-full">
          {user?.profileImg ? (
            <ImageView
              className="w-[70px] h-[70px] rounded-full object-cover"
              src={user?.profileImg}
              alt="user"
            />
          ) : (
            <div className="bg-primary w-[70px] h-[70px] rounded-full text-white text-lg flex justify-center items-center font-semibold">
              {getInitial(user?.firstName, user?.lastName)}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-4">
            <span className="text-[22px] text-[#242424]">
              {user?.firstName} {user?.lastName}
            </span>
            <div className="bg-[#D0EADC] rounded text-[11px] w-24 h-6 flex items-center justify-center">
              Active Today
            </div>
          </div>
          <div className="text-sm text-[#242424]">Level 2, Sales Rep</div>
          <div className="flex items-center gap-4 text-sm text-[#242424]">
            <span>{user?.email}</span>
            <span>{user?.phone}</span>
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        {/* <button className="text-sm text-[#242424] flex items-center gap-1.5 hover:opacity-80">
          <EditIcon className="w-4 h-4" />
          Edit Account
        </button> */}
        {/* <MoreActionView onDelete={() => {}} /> */}
      </div>
    </div>
  );
};
