import { useState, useRef, useEffect } from "react";
import { Button } from "../../../components/Button";
import { SelectInput } from "../../../components/SelectInput/SelectInput";
import { api } from "../../../services/api";
import { useMutation } from "@tanstack/react-query";
import { useUser } from "../../../services/user-context";

const options = [
  { label: "Yes", value: 1, color: "#14AE5C" },
  { label: "No", value: 0, color: "#FA3A1A" },
];

const scoringMethods = [
  { label: "Average", value: "average" },
  { label: "Highest", value: "highest" },
];

function Field({
  label,
  children,
  className = "",
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      <label className="text-[13px] text-black">{label}</label>
      {children}
    </div>
  );
}

function BooleanSelect({
  value,
  onChange,
}: {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) {
  return (
    <select
      className="border w-[90px] border-[#E9E9E9] rounded px-2 py-1 !outline-none"
      style={{
        color: value === 1 ? options[0].color : options[1].color,
      }}
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <option key={`${option.value}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export const CertificationFormModal = ({
  onClose,
  isOpen,
  territories,
  regions,
  products,
  onSuccess,
}: {
  onClose: () => void;
  isOpen: boolean;
  territories: any[];
  regions: any[];
  products: any[];
  onSuccess: () => void;
}) => {
  console.log(territories, regions, products);
  const { userInfo } = useUser() as any;
  const { mutate, isLoading, error } = useMutation({
    mutationFn: (data: any) => api.createCertification(data),
    onSuccess: () => {
      onClose();
      onSuccess();
    },
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const [form, setForm] = useState({
    isActive: 1,
    certificationAttemptsAllowed: 1,
    certificationStartDate: "",
    certificationDueDate: "",
    certificationName: "",
    regionId: "",
    territoryId: "",
    isLocationBased: 0,
    isProductBased: 0,
    productId: "",
    scoringMethod: "average",
    // id: "",
  });

  const handleSave = async () => {
    try {
      const payload = {
        ...form,
        isProductBased: form.isProductBased === 1,
        isLocationBased: form.isLocationBased === 1,
        isActive: form.isActive === 1,
        createdBy: userInfo?.id,
      };
      mutate(payload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // close modal when clicking outside
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        isOpen
      ) {
        onClose();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  console.log(userInfo);
  return (
    <div
      ref={containerRef}
      className="bg-white p-6 w-full fixed rounded-[15px] md:w-[575px] right-0 md:right-8 top-0 h-full md:h-auto z-[10] md:top-28 shadow-[0px_10px_23px_0px_#00000040]"
    >
      <div className="flex flex-col gap-4">
        <div>
          <div className="text-black text-2xl font-bold">New Certification</div>
          <div className="text-black text-[15px] mt-1">
            Fill out the form below to create a new Certification
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <Field label="Is Active?">
            <BooleanSelect
              value={form.isActive}
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  isActive: Number(e.target.value),
                }));
              }}
            />
          </Field>

          <Field label="Attempts Allowed">
            <select
              className="border w-[90px] border-[#E9E9E9] rounded px-2 py-1 !outline-none"
              value={form.certificationAttemptsAllowed}
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  certificationAttemptsAllowed: Number(e.target.value),
                }));
              }}
            >
              {[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
              ].map((option) => (
                <option key={`${option.value}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Field>
        </div>
        <div className="flex gap-4 items-start">
          {/* {form.id && (
            <Field label="Certification ID (UUID)">
              <span className="text-[#A6A6A6] text-[13px]">{form.id}</span>
            </Field>
          )} */}
          <Field label="Certification Name" className="flex-1">
            <input
              type="text"
              className="border border-[#E9E9E9] rounded px-2 py-1 !outline-none"
              value={form.certificationName}
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  certificationName: e.target.value,
                }));
              }}
            />
          </Field>
        </div>
        <div className="flex gap-4 items-center">
          <Field label="Certification Start Date" className="flex-1">
            <input
              type="date"
              className="border border-[#E9E9E9] w-48 rounded px-2 py-1 !outline-none"
              value={form.certificationStartDate}
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  certificationStartDate: e.target.value,
                }));
              }}
            />
          </Field>
          <Field label="Certification Due Date" className="flex-1">
            <input
              type="date"
              className="border border-[#E9E9E9] w-48 rounded px-2 py-1 !outline-none"
              value={form.certificationDueDate}
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  certificationDueDate: e.target.value,
                }));
              }}
            />
          </Field>
        </div>
        <div className="flex gap-4 items-center">
          <Field label="Certification Assignment Location Level">
            <select
              className="border border-[#E9E9E9] rounded px-2 py-1 !outline-none"
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  regionId: e.target.value,
                }));
              }}
              value={form.regionId}
            >
              <option value="" disabled>
                Select Location Level
              </option>
              {regions.map((option) => (
                <option key={`${option.id}`} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </Field>
          <Field label="Scoring Method">
            <select
              className="border border-[#E9E9E9] rounded px-2 py-1 !outline-none"
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  scoringMethod: e.target.value,
                }));
              }}
              value={form.scoringMethod}
            >
              <option value="" disabled>
                Select Scoring Method
              </option>
              {scoringMethods.map((option) => (
                <option key={`${option.value}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Field>
        </div>
        <div className="flex gap-4 items-center">
          <Field label="Is Location Based?">
            <BooleanSelect
              value={form.isLocationBased}
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  isLocationBased: Number(e.target.value),
                }));
              }}
            />
          </Field>
          <Field label="Location Name" className="flex-1">
            <SelectInput
              value={form.territoryId}
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  territoryId: e.target.value,
                }));
              }}
              options={territories?.map((territory) => ({
                label: territory.name,
                value: territory.id,
              }))}
              placeholder="Select Location"
            />
          </Field>
        </div>

        <div className="flex gap-4 items-center">
          <Field label="Is Product Based?">
            <BooleanSelect
              value={form.isProductBased}
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  isProductBased: Number(e.target.value),
                }));
              }}
            />
          </Field>
          <Field label="Product Name" className="flex-1">
            <SelectInput
              value={form.productId}
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  productId: e.target.value,
                }));
              }}
              options={products?.map((product) => ({
                label: product.name,
                value: product.id,
              }))}
              placeholder="Select Product"
            />
          </Field>
        </div>
        <div className="flex justify-end">
          <Button
            onClick={handleSave}
            variant="primary"
            className="h-[58px] !rounded-full "
            loading={isLoading}
          >
            Create Certification
          </Button>
        </div>
      </div>
    </div>
  );
};
