export const processingMessages = [
  "Hmm, that's an interesting question. Let me think for a moment.",
  "Give me a second to gather my thoughts on that.",
  "I'm considering all the possibilities. Just a moment.",
  "That's a tough one. Let me chew on it for a bit.",
  "I'm thinking about that. Just a moment.",
  "Hmm, let me run through some scenarios before I respond.",
];

export enum PractitionerTypes {
  "Internal_Medicine" = "Internal Medicine",
  "Family_Medicine" = "Family Medicine",
  "Gastroenterology" = "Gastroenterology",
  "Pulmonology" = "Pulmonology",
}

export enum PersonalityTypes {
  "Clinical_Data_Driven" = "Clinical Data Driven",
  "Skeptical" = "Skeptical",
  "Story_Telling" = "Story-Telling",
  "Agreeable" = "Agreeable",
  "Busy" = "Busy",
  "To_The_Point" = "To-The-Point",
}
// "Data_Driven"= "Data Driven",

export const userRoles = {
  Admin: "A",
  Manager: "M",
  Trainer: "T",
  Person: "P",
};

export const certificationStatus = {
  Pass: {
    bgColor: "#14AE5C1A",
    textColor: "#14AE5C",
    label: "Pass",
  },
  Fail: {
    bgColor: "#FF00001A",
    textColor: "#FA3A1A",
    label: "Failed",
  },
  InReview: {
    bgColor: "#FFBA533B",
    textColor: "#9F7230",
    label: "In Review",
  },
  NotStarted: {
    bgColor: "#9C9C9C1A",
    textColor: "#808080",
    label: "Not Started",
  },
};

export enum ObjectionLevels {
  "L" = "L",
  "M" = "M",
  "H" = "H",
}

export const ObjectionLevelTitles = {
  L: "Low",
  M: "Medium",
  H: "High",
};

export const ReportInsights = {
  languageClarity: "Grammar",
  vocabulary: "Vocabulary",
  tone: "Tone",
  brandStrategy: "Brand Alignment",
  compliancePercentage: "Compliance",
  questionTechniques: "Questioning",
  speakListenRatio: "Active Listening",
  openClosedQuestions: "Product Knowledge",
};
