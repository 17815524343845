import { Routes, Route } from "react-router-dom";
import { Main } from "./main";
import SuspenseWrapper from "../components/SuspenseWrapper";
import { Login } from "./auth/login/Login";
import { ForgotPassword } from "./auth/ForgotPassword";
import { ResetPassword } from "./auth/ResetPassword";
import { Register } from "./auth/register/Register";
import { PraxisPlay } from "./praxis-play/PraxisPlay";
import { Certifications } from "./certifications/Certifications";
import { UserCertificationView } from "./certifications/manager-certification/UserCertificationView";
import { CertificationView } from "./certifications/manager-certification/CertificationView";
import { PraxisPlayDashboard } from "./praxis-play-dashboard/PraxisPlayDashboard";
import { SessionReportDetails } from "./praxis-play-dashboard/components/SessionReportDetails";
// import OutboundCalls from "./OutboundCalls";
import { OutBoundCall } from "./out-bound-call/OutBoundCall";

const RoutesList = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      <Route path="/" element={<Main />}>
        <Route path="praxisplay">
          <Route
            path="dashboard"
            element={
              <SuspenseWrapper>
                <PraxisPlayDashboard />
              </SuspenseWrapper>
            }
          />
          <Route
            path="session/:sessionId"
            element={
              <SuspenseWrapper>
                <SessionReportDetails />
              </SuspenseWrapper>
            }
          />
          <Route
            path="role-play"
            element={
              <SuspenseWrapper>
                {/* <PraxisPlayDashboard /> */}
                <PraxisPlay />
              </SuspenseWrapper>
            }
          />
        </Route>
        {/* <Route
          path="praxisplay/dashboard"
          element={
            <SuspenseWrapper>
              <PraxisPlay />
            </SuspenseWrapper>
          }
        /> */}
        <Route
          path="praxisplay/role-play"
          element={
            <SuspenseWrapper>
              <PraxisPlay />
            </SuspenseWrapper>
          }
        />
        <Route
          path="certifications"
          element={
            <SuspenseWrapper>
              <Certifications />
            </SuspenseWrapper>
          }
        />
        <Route
          path="certifications/review/:certificationId"
          element={
            <SuspenseWrapper>
              <CertificationView />
            </SuspenseWrapper>
          }
        />
        <Route
          path="certifications/user/:userId"
          element={
            <SuspenseWrapper>
              <UserCertificationView />
            </SuspenseWrapper>
          }
        />
        <Route
          path="outbound-calls"
          element={
            <SuspenseWrapper>
              <OutBoundCall />
            </SuspenseWrapper>
          }
        />
        {/* <Route
          index
          element={
            // if loggged in redirect to dashboard
            <Navigate to="/login" />
          }
        /> */}
      </Route>
    </Routes>
  );
};
export default RoutesList;
