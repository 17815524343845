import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { ReportsTable } from "./components/ReportsTable";
import { SessionsCard } from "./components/SessionsCard";
import { PraxisScoreCard } from "./components/PraxisScoreCard";
import { DailyChallenge } from "./components/DailyChallenge";
import { Card } from "../praxis-play/components/Carousel";
import { PraxisproInsights } from "./components/PraxisproInsights";
import { useState } from "react";
import { ReportView } from "../praxis-call/components/ReportView";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { BackButton } from "../../components/Button/BackButton";

export function PraxisPlayDashboard() {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [filter, setFilter] = useState<any>({});
  const [showSessionList, setShowSessionList] = useState(false);
  const handleBegin = () => {
    navigate("/praxisplay/role-play");
  };

  if (selectedRow) {
    return (
      <ReportView
        report={selectedRow}
        isReport={true}
        onBackClick={() => setSelectedRow(null)}
      />
    );
  }
  return (
    <div className="p-4 md:p-6 !pt-[80px] pb-8 lg:!pt-4 md:flex md:flex-col md:h-[100vh] lg:h-full">
      <div className="flex justify-between items-center">
        <div className="flex-col justify-start items-start tall2:gap-2 gap-2 md:gap-4 inline-flex">
          <div className="flex items-center gap-2">
            {selectedRow || showSessionList ? (
              <BackButton
                onClick={() => {
                  setSelectedRow(null);
                  setShowSessionList(false);
                }}
              />
            ) : null}
            <div className="text-black text-2xl font-bold">Praxis Play</div>
          </div>
          <div className="text-black text-sm md:text-lg font-normal">
            Sharpen your product knowledge and skills with interactive sessions
            with an AI-generated character.
          </div>
        </div>

        <Button
          variant="primary"
          className="w-full md:w-auto px-7 !py-4 !rounded-full !text-lg !font-medium"
          onClick={handleBegin}
        >
          Begin Praxis Play
        </Button>
      </div>
      {!showSessionList && (
        <>
          <div className="flex flex-wrap flex-col md:flex-row items-center gap-4 my-4 justify-between">
            <SessionsCard setShowSessionList={setShowSessionList} />
            <PraxisScoreCard />
            <DailyChallenge />
          </div>
          <PraxisproInsights />
        </>
      )}
      {showSessionList && (
        <>
          <div className="md:self-center md:flex-1 tall2:h-[calc(100vh-118px)] h-auto overflow-auto w-full tall2:mt-3 mt-4 md:mt-7 px-6 py-6 bg-white rounded-2xl shadow border flex-col justify-start items-start tall2:gap-2 gap-6 flex">
            <div className="flex justify-between items-center w-full mb-3">
              <div className="text-black text-2xl font-bold">Sessions</div>
              <div className="relative w-fit bg-[#F3F3F3] rounded-md">
                <input
                  type="text"
                  placeholder="Search"
                  className="p-2 !pl-12 rounded-md !outline-none bg-[#F3F3F3]"
                  value={filter.search}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      search: e.target.value,
                    })
                  }
                />
                <SearchIcon className="w-6 h-6 absolute left-3 top-1/2 -translate-y-1/2 [&_path]:stroke-black" />
              </div>
            </div>
            <ReportsTable
              filter={filter}
              onRowClick={(row) => setSelectedRow(row)}
            />
          </div>
        </>
      )}
    </div>
  );
}
