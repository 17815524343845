import { useState, useEffect } from "react";
import { Button } from "../../components/Button";
import ConnectStreamsCCP from "../components/ConnectStreamsCCP";
import { ContactsTable } from "./components/ContactsTable";
import { Loading } from "../../components/Loading/Loading";
import Lottie from "lottie-react";
import * as animationData from "../../assets/loading-bar.json";

export function OutBoundCall() {
  const [isCCPInitialized, setIsCCPInitialized] = useState(false);
  const ccpUrl = "https://praxispro-sandbox-east.my.connect.aws/connect/ccp-v2";

  const handleIdentifyDevices = () => {
    // user media
  };

  return (
    <div className="p-4 md:p-6 !pt-[80px] pb-8 lg:!pt-4 md:flex md:flex-col md:h-[100vh] lg:h-full">
      <div className="flex justify-between items-center">
        <div className="flex-col justify-start items-start tall2:gap-2 gap-2 md:gap-4 inline-flex">
          <div className="flex items-center gap-2">
            <div className="text-black text-2xl font-bold">Outbound calls</div>
          </div>
        </div>
      </div>
      <div className="md:self-center md:flex-1 tall2:h-[calc(100vh-118px)] h-auto overflow-auto w-full tall2:mt-3 mt-4 md:mt-7 px-6 py-6 bg-white rounded-2xl shadow border flex-col justify-start items-start tall2:gap-2 gap-6 flex">
        {isCCPInitialized && <ContactsTable />}
        {!isCCPInitialized && (
          <div className="w-full h-full flex justify-center items-center">
            <Lottie
              animationData={animationData}
              loop={true}
              className={`w-[175px] h-[175px]`}
            />
          </div>
        )}
      </div>
      <ConnectStreamsCCP
        ccpUrl={ccpUrl}
        containerId="ccp-container"
        onInit={() => {
          console.log("onInit");
          setIsCCPInitialized(true);
        }}
      />
    </div>
  );
}
