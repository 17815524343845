import { PerformanceCard } from "./PerformanceCard";
import { AIOverview } from "../AIOverview";
import { Card } from "../../../../components/Card";

export function AIFeedback({ report, metrics }) {
  return (
    <div className="flex flex-col lg:flex-row items-stretch gap-5 mb-5">
      <div className="flex flex-col items-stretch gap-5 mb-5">
        <div className="flex-1 w-full">
          <AIOverview report={report} />
        </div>
        <div className="flex-1 w-full h-full">
          <Card className="h-full">
            <div className="text-[20px] font-medium text-black mb-3">
              Field Coaching Report Priorities
            </div>
            <div className="flex flex-col gap-4">
              <PerformanceCard
                title="Questioning Techniques"
                description={metrics?.questionTechniques?.feedback}
                percentage={metrics?.questionTechniques?.value || 0}
              />
              <PerformanceCard
                title="Drug to Drug Interactions"
                description={metrics?.drugToDrugInteractions?.feedback || "-"}
                percentage={metrics?.drugToDrugInteractions?.value || 0}
              />
              <PerformanceCard
                title="Compliance"
                description={metrics?.compliancePercentage?.feedback || "-"}
                percentage={metrics?.compliancePercentage?.value || 0}
              />
            </div>
          </Card>
        </div>
      </div>
      <div className="flex flex-col items-stretch gap-5 mb-5">
        <div className="w-full h-full">
          <Card className="h-full">
            <div className="text-[20px] font-medium text-black mb-3">
              Opportunities
            </div>
            <div className="flex flex-col gap-4">
              <PerformanceCard
                title="Compliance"
                description={metrics?.compliancePercentage?.feedback || "-"}
                percentage={metrics?.compliancePercentage?.value || 0}
              />
              <PerformanceCard
                title="Vocabulary"
                description={metrics?.vocabulary?.feedback || "-"}
                percentage={metrics?.vocabulary?.value || 0}
              />
            </div>
          </Card>
        </div>
        <div className="flex-1 w-full h-full">
          <Card className="h-full">
            <div className="text-[20px] font-medium text-black mb-3">
              Strengths
            </div>
            <div className="flex flex-col gap-4">
              <PerformanceCard
                title="Questioning Techniques"
                description={metrics?.questionTechniques?.feedback || "-"}
                percentage={metrics?.questionTechniques?.value || 0}
              />
              <PerformanceCard
                title="Product Knowledge"
                description="Your efficacy message aligned to the prescribing information and clinical data regarding your product."
                percentage={76}
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
