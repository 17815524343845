import { Link } from "react-router-dom";
import { Card } from "../../../components/Card";
import { CallInsight } from "../../praxis-call/components/report-tabs/Performance";

const getPercentage = (completed: number, total: number) => {
  return (completed / total) * 100;
};

export const DailyChallenge = () => {
  return (
    <Card className="!px-0 !py-6 flex-1 !w-full md:min-w-[350px]">
      <div className="flex px-6 justify-between items-center mb-[19px] gap-6 lg:gap-11">
        <div className="text-xl font-bold">Daily Challenge</div>
        <div>
          <button className="uppercase text-primary2 hover:text-primary2/80 text-sm font-semibold cursor-pointer">
            View All
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-4 px-4">
        <p className="text-sm text-text2">
          Complete 8 interactions a day to reach your goal!
        </p>
        <div className="border-b border-[#dddddd] w-full" />
        <div className="flex items-center [&_.label]:!text-xs [&_.label]:text-black [&>div]:!py-0 [&>div]:!px-1">
          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
            <CallInsight
              title={day}
              percentage={getPercentage(7, 8)}
              radius={19}
              strokeWidth={2}
              key={day}
              customText={`7/8`}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};
