import { useState } from "react";
import { Card } from "../../components/Card";
import { CertificationTable } from "./components/CertificationTable";
import { FilterView } from "./manager-certification/components/FilterView";

export function UserCertifications() {
  const [filter, setFilter] = useState({
    sortBy: "createdAt",
    sortOrder: "desc",
    search: "",
  });

  return (
    <div className="bg-[#F3F1F2] p-4 pt-20 lg:p-8">
      <div className="md:mb-8 flex items-center justify-between">
        <div>
          <div className="text-black text-2xl font-bold">
            Certification Metrics
          </div>
          <div className="text-black text-lg mt-3">
            View your certification praxis play sessions
          </div>
        </div>
      </div>
      <FilterView filter={filter} setFilter={setFilter} />
      <Card>
        <CertificationTable type="user-dashboard" filter={filter} />
      </Card>
    </div>
  );
}
