export function Tabs({ value, onChange, tabs }) {
  return (
    <div>
      {tabs.map((tab) => (
        <button
          key={tab.value}
          onClick={() => onChange(tab.value)}
          className={`report-tab-button relative text-base p-2.5 ${
            value === tab.value
              ? "text-primary selected-report-tab-button font-semibold"
              : "text-black hover:text-black/70 font-normal"
          }`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
}
