import { PassFailSelect } from "../../components/PassFailSelect";

const complianceTasks = [
  {
    title: "Adherence to HIPAA Guidelines",
    name: "adherenceToHIPAAGuidelines",
  },
  {
    title: "Accurate Product Claims",
    name: "accurateProductClaims",
  },
  {
    title: "Understanding of Drug Mechanism",
    name: "understandingOfDrugMechanism",
  },
];

export function CertificationReview({
  form,
  setForm,
}: {
  form: any;
  setForm: any;
}) {
  return (
    <div className="relative bg-white pt-11 rounded-md border border-[#DEDEDE] w-full">
      <div className="absolute top-0 left-0 w-full border border-[#DEDEDE] bg-[#B4B4B421] rounded-[7px] h-11 flex items-center px-4 text-[#242424] text-[17px] leading-[20.5px]">
        Manager Comments and Feedback
      </div>
      <div className="p-4">
        <table className="bg-white rounded-md p-4 w-full">
          <thead>
            <tr className="flex items-center justify-between mb-1">
              <td className="text-black text-[13px] leading-[15.44px] font-medium">
                Compliance Task
              </td>
              <td className="text-black text-[13px] leading-[15.44px] font-medium w-[100px] text-center">
                Pass/Fail
              </td>
            </tr>
          </thead>
          <tbody>
            {complianceTasks.map((task) => (
              <tr className="flex items-center justify-between mb-1">
                <td className="text-[#242424] text-[11px] leading-[13.2px]">
                  {task.title}
                </td>
                <td>
                  <PassFailSelect
                    value={form[task.name]}
                    onChange={(value) =>
                      setForm({ ...form, [task.name]: value })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
