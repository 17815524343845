import React from "react";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/chevron-left.svg";

export function BackButton({ onClick }: { onClick?: () => void }) {
  const handleBack = () => {
    if (onClick) {
      onClick();
    } else {
      window.history.back();
    }
  };
  return (
    <button onClick={handleBack} className="flex items-center gap-2">
      <ArrowLeftIcon className="w-4 h-4" />
      Back
    </button>
  );
}
