import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/icons/Logo.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg";
import { ReactComponent as SmallLogo } from "../../assets/icons/small-logo.svg";
import { ReactComponent as DesktopIcon } from "../../assets/icons/desktop.svg";
import { ReactComponent as MetricsIcon } from "../../assets/icons/metrics.svg";
import { ReactComponent as HeadphoneIcon } from "../../assets/icons/headphone.svg";

import { getInitial } from "../../services/utils";
import { ImageView } from "../Image";
import { useApp } from "../../services/app-context";
import { userRoles } from "../../constant";
import { useEffect, useMemo, useState } from "react";
import useResponsive from "../../services/useResponsive";

export const menuItems = [
  {
    title: "PraxisPlay",
    Icon: DesktopIcon,
    // path: "/praxisplay/dashboard", // TODO: bring back once api is ready
    path: "/praxisplay/role-play", // TODO: remove once api is ready
    pathName: "/praxisplay",
  },
  {
    title: "Certifications",
    Icon: MetricsIcon,
    path: "/certifications",
    needCertificationMode: true,
    pathName: "/certifications",
  },
  // {
  //   title: "Outbound Calls",
  //   Icon: HeadphoneIcon,
  //   path: "/outbound-calls",
  //   pathName: "/outbound-calls",
  // },
];

const checkPermission = (menu, tenantProfile) => {
  if (menu.needCertificationMode) {
    const isCertificationMode = !!tenantProfile?.subscriptions?.certifications;
    if (isCertificationMode) {
      return true;
    }
    return false;
  }
  return true;
};

export function Sidebar({
  user,
  onEdit,
  userInfoLoading,
}: {
  user: any;
  onEdit: () => void;
  userInfoLoading: boolean;
}) {
  console.log("user", user);
  const { isTablet } = useResponsive();
  const [collapsed, setCollapsed] = useState(false);
  const { tenantProfile } = useApp();
  const location = useLocation();
  const { logout } = useApp();
  const isSelected = (path) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return false;
  };

  const handleLogout = () => {
    logout();
  };

  const menus = useMemo(() => {
    if (userInfoLoading) {
      return menuItems;
    }
    if (user?.role === userRoles.Manager) {
      return [
        ...menuItems,
        // {
        //   title: "Users",
        //   Icon: UsersIcon,
        //   path: "/users",
        // },
      ];
    }
    return menuItems;
  }, [user, userInfoLoading]);

  useEffect(() => {
    if (isTablet) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isTablet]);

  return (
    <>
      <div className="hidden lg:flex max-h-[100vh] overflow-auto bg-[#7C2928] border-r border-gray-200 flex-col">
        <div
          className={`self-stretch py-8 border-b border-white/20 justify-center items-center flex ${
            collapsed ? "px-7" : "px-12"
          }`}
        >
          <div className="justify-center items-center flex">
            {!collapsed && <Logo />}
            {collapsed && <SmallLogo />}
          </div>
        </div>
        <div className="flex h-[calc(100vh-80px)] justify-between flex-col">
          <div className="flex-col justify-start items-start gap-5 flex">
            <div className="flex-col justify-start items-start flex w-full p-4 gap-2">
              {menus
                .filter((item) => checkPermission(item, tenantProfile))
                .map((item, index) => (
                  <Link
                    to={item.path}
                    key={index}
                    className={`w-full py-5 rounded-[12px] justify-start items-center gap-3 flex group hover:bg-white/[0.08] transition duration-75 hover:scale-[1.02] ${
                      isSelected(item.pathName) ? "!bg-white/[0.17]" : ""
                    } ${collapsed ? " justify-center px-0" : "px-6"}`}
                  >
                    <item.Icon />

                    {!collapsed && (
                      <div className="grow shrink basis-0 text-white text-sm">
                        {item.title}
                      </div>
                    )}
                  </Link>
                ))}
            </div>
          </div>
          <div
            className={`mb-4 p-5 bg-black bg-opacity-20 rounded-2xl border border-white border-opacity-20 flex-col justify-start items-center gap-3 inline-flex ${
              collapsed ? "w-[72px] mx-2.5 !py-3" : "mx-4"
            }`}
          >
            <div className={`${collapsed ? "w-12 h-12" : "w-16 h-16"}`}>
              {user?.profileImg ? (
                <ImageView
                  className="w-full h-full rounded-full object-cover"
                  src={user?.profileImg}
                  alt="user"
                />
              ) : (
                <div
                  className={`bg-primary w-full h-full rounded-full text-white flex justify-center items-center font-semibold ${
                    collapsed ? "text-xl" : "text-3xl"
                  }`}
                >
                  {getInitial(user?.given_name, user?.family_name)}
                </div>
              )}
            </div>
            {!collapsed && (
              <div
                className="text-center text-white text-base font-bold max-w-[225px] overflow-hidden text-ellipsis whitespace-nowrap"
                title={`${user.given_name} ${user.family_name}`}
              >
                {user.given_name} {user.family_name}
              </div>
            )}
            <div
              className={`justify-start items-center gap-3.5 flex ${
                collapsed ? "flex-col" : "flex-row"
              }`}
            >
              <div className="justify-start items-center gap-1 group flex">
                <button
                  onClick={onEdit}
                  className={`flex items-center gap-1 text-center group-hover:text-indigo-600 text-indigo-200 text-base font-normal ${
                    collapsed ? "py-2" : ""
                  }`}
                >
                  <Pencil
                    className={`w-5 h-5 relative group-hover:[&_path]:stroke-indigo-600`}
                  />
                  {!collapsed && "Edit"}
                </button>
              </div>
              {!collapsed && (
                <div className="border-l border-[#C4BEDC] h-[80%]"></div>
              )}
              <div className="justify-start group">
                <button
                  onClick={handleLogout}
                  className={`text-center items-center gap-1 flex group-hover:text-red-600 text-red-300 text-base font-normal ${
                    collapsed ? "py-2" : ""
                  }`}
                >
                  <Logout
                    className={`w-5 h-5 relative group-hover:[&_path]:stroke-red-600`}
                  />
                  {!collapsed && "Logout"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
