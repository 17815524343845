import apiClient, { buildUrl, getToken } from "./axios";
import { UserAttributes } from "./types";

export const api = {
  login: (data: any) => {
    return apiClient.post("/auth/login", data);
  },
  createCharacter: (data: any) => {
    return apiClient.post("/characters", data);
  },
  getCharacters: () => {
    return apiClient.get("/characters");
  },
  startCall: (data: any) => {
    return apiClient.post("/call", data);
  },
  endCall: (callId: string) => {
    return apiClient.patch(`/call/${callId}`, {
      isEnded: true,
    });
  },
  fetchReport: (sessionId: string, data: any) => {
    return apiClient.post(`/call/${sessionId}/reports`, data);
  },
  getReports: (callId: string) => {
    // /call/{id}/reports
    return apiClient.get(`/call/${callId}/reports`);
  },
  sendFullTextResponse: (callId: string, data: any) => {
    return apiClient.post(`/call/${callId}/query-response`, data);
  },
  uploadCallPart: (callId: string, form: any) => {
    return fetch(`${buildUrl(`call/${callId}/response`)}`, {
      method: "POST",
      headers: {
        Authorization: `${getToken()}`,
      },
      body: form,
    });
  },
  socialLogin: (data: any) => {
    return apiClient.post("/auth/login/social", data);
  },
  initEmailVerification: (id: any) => {
    return apiClient.post(`/users/${id}/email/verify/initiate`);
  },
  verifyEmail: (id: any, data: any) => {
    return apiClient.post(`/users/${id}/email/verify`, data);
  },
  signup: (data: any) => {
    return apiClient.post("/auth/register", data).then((res) => res.data);
  },
  logout: () => {
    return apiClient.post("/auth/logout").then((res) => res.data);
  },
  changePassword: (
    id: string,
    data: {
      oldPassword: string;
      newPassword: string;
    }
  ) => {
    return apiClient.patch(`/users/${id}/password`, data);
  },
  forgotPassword: (data: any) => {
    return apiClient.post("/auth/forgot-password", data);
  },
  resetPassword: (data: any) => {
    return apiClient.post("/auth/reset-password", data);
  },
  getUserProfile: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  saveLastActive: (id: string) => {
    return apiClient.post(`/users/${id}/active`);
  },
  saveUserProfile: (id: string, data: any) => {
    return apiClient.put(`/users/${id}`, data);
  },
  getProgressDashboard: (userId: string, month: string, year: string) => {
    return apiClient
      .get(`/users/${userId}/progress-dashboard?month=${month}&year=${year}`)
      .then((r) => r.data);
  },
  getDashboard: (userId: string) => {
    return apiClient.get(`/users/${userId}/dashboard`).then((r) => r.data);
  },
  intro: (userId: string) => {
    return fetch(`${buildUrl(`users/${userId}/intro`)}`, {
      method: "POST",
      headers: {
        Authorization: `${getToken()}`,
      },
    });
  },
  getPredesignedUrl: ({
    id,
    mimeType,
    uploadType,
  }: {
    id: string;
    mimeType: string;
    uploadType: string;
  }) => {
    let url = `/users/${id}/images/signed-url?mimeType=${mimeType}&uploadType=${uploadType}`;

    return apiClient.get(url).then((r) => r.data);
  },
  //d-id api
  connectStream: (data: any) => {
    let url = `/did/streams`;
    return apiClient.post(url, data);
  },
  connectIceCandidate: (streamId: string, data: any) => {
    let url = `/did/streams/${streamId}/ice`;
    return apiClient.post(url, data);
  },
  connectSdp: (streamId: string, data: any) => {
    let url = `/did/streams/${streamId}/sdp`;
    return apiClient.post(url, data);
  },
  getStream: (streamId: string, data: any) => {
    let url = `/did/streams/${streamId}`;
    return apiClient.post(url, data);
  },
  getAssemblyAIToken: () => {
    let url = "/auth/assembly-ai/token";
    return apiClient.post(url);
  },
  chatStream: (data: { query: string; callId: string }) => {
    const url = `call/${data.callId}/query`;
    return fetch(`${buildUrl(url)}`, {
      method: "POST",
      headers: {
        Authorization: `${getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query: data.query }),
    }).then((res) => res.text());
  },
  createPlayback: ({ sessionId }: { sessionId: string }) => {
    let url = `call/${sessionId}/create-playback`;
    return apiClient.get(url);
  },
  createCertification: (data: any) => {
    let url = `/certifications`;
    return apiClient.post(url, data);
  },
  getTerritories: () => {
    let url = `/certifications/territories`;
    return apiClient.get(url);
  },
  getRegions: () => {
    let url = `/certifications/regions`;
    return apiClient.get(url);
  },
  getProducts: () => {
    let url = `/certifications/products`;
    return apiClient.get(url);
  },
  getAssignments: ({ userId }: any) => {
    let url = `/certifications/assignments`;
    if (userId) {
      url = `${url}/${userId}`;
    }
    return apiClient.get(url);
  },
  getCertificationList: () => {
    let url = `/certifications`;
    return apiClient.get(url);
  },
  searchUsers: () => {
    let url = `/users/search/filtered`;
    return apiClient.get(url).then((res) => res.data);
  },
  getUsers: () => {
    let url = `/users`;
    return apiClient.get(url).then((res) => res.data);
  },
  updateUserAttributes: (id: string, data: UserAttributes) => {
    let url = `/users/${id}/attributes`;
    return apiClient.patch(url, data);
  },
  getUserAttributes: (id: string) => {
    let url = `/users/${id}/attributes`;
    return apiClient.get(url);
  },
  searchCertifications: (data: any) => {
    let url = `/certifications/search`;
    return apiClient.get(url, { params: data });
  },
  fetchPersonalityContext: (data: any) => {
    let url = `/call/personality_context`;
    return apiClient.post(url, data);
  },
};
