import { performanceColors } from "../PerformanceView";

export function PerformanceCard({
  title,
  description,
  percentage,
}: {
  title: string;
  description: string;
  percentage: number;
}) {
  let color = performanceColors.low;
  if (percentage > 40) {
    color = performanceColors.medium;
  }
  if (percentage > 70) {
    color = performanceColors.high;
  }
  return (
    <div
      className={`py-[27px] px-[22px] flex items-start gap-4 rounded-[10px] ${color.progressBg}`}
    >
      <span className="font-bold text-[15px]">{title}</span>
      <div className="text-[15px] font-normal">{description}</div>
    </div>
  );
}
