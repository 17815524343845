import { useState } from "react";

import { CertificationReview } from "./components/CertificationReview";
import { ManagerCommentsInput } from "./components/ManagerCommentsInput";
import { Card } from "../../../components/Card";
import { PerformanceView } from "../../praxis-call/components/PerformanceView";
import { CertificationProfileView } from "./components/CertificationProfileView";
import { useCertification } from "../context/certification-context";

export const CertificationView = () => {
  const { selectedCertificationAssignment } = useCertification();
  const [form, setForm] = useState<any>({});
  const report = {
    languageClarity: 0.8,
    vocabulary: 0.7,
    brandStrategy: 0.9,
    questionTechniques: 0.6,
    compliancePercentage: 0.8,
    tone: 0.7,
  };

  return (
    <div className="bg-[#F3F1F2] p-4 lg:p-8">
      <div className="md:mb-4 flex items-center justify-between">
        <div>
          <div className="text-black text-2xl font-bold">
            Certification Metrics
          </div>
          <div className="text-black text-lg mt-1">
            View Certification Praxis Play sessions for your team
          </div>
        </div>
      </div>
      <Card>
        <CertificationProfileView
          certificationAssignment={selectedCertificationAssignment}
        />
        <div className="flex items-start gap-4 mt-4">
          <div className="flex flex-col gap-4 max-w-[50vw]">
            <div className="h-[478px] w-full rounded-lg">
              <video
                className="w-full h-full object-cover rounded-lg"
                src={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"}
                controls
                playsInline
                onError={(e) => {
                  console.log(e);
                }}
              />
            </div>
            <div className="flex flex-col items-start gap-5 border border-[#DEDEDE] rounded-lg p-4">
              <h4 className="text-[17px] text-[#242424]">Insights</h4>
              <div className="w-full rounded-[14px] grid grid-cols-2 gap-4">
                <PerformanceView
                  title="Language Clarity and Precision"
                  value={report?.languageClarity || 0}
                />
                <PerformanceView
                  title="Vocabulary and Terminology"
                  value={report?.vocabulary || 0}
                />
                <PerformanceView
                  title="Brand Strategy Alignment"
                  value={report?.brandStrategy || 0}
                />
                <PerformanceView
                  title="Questioning Techniques"
                  value={report?.questionTechniques || 0}
                />
                <PerformanceView
                  title="Compliance"
                  value={report?.compliancePercentage || 0}
                />
                <PerformanceView title="Tone" value={report?.tone} />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 flex-1">
            <CertificationReview form={form} setForm={setForm} />
            <ManagerCommentsInput
              value={form.managerComments}
              onChange={(value) => setForm({ ...form, managerComments: value })}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
