import { useState } from "react";
import { Button } from "../../components/Button";
import { usePraxisCall } from "../praxis-play/context/praxiscall-context";
import { FCRReport } from "./FCRReport";
import { Tabs } from "./components/Tabs";
import { Performance } from "./components/report-tabs/Performance";
import { AIFeedback } from "./components/report-tabs/AIFeedback";
import { SessionRecap } from "./components/report-tabs/SessionRecap";
import CallAudio from "./CallAudio";
import { ReportView } from "./components/ReportView";

export type Conversation = {
  id: string;
  message: string;
  isUserMessage: boolean;
  session_url?: string | null;
  audio_file?: any;
};

type Report = {
  conversations: Conversation[];
  languageClarity: number;
  vocabulary: number;
  brandStrategy: number;
  questionTechniques: number;
  compliancePercentage: number;
  tone: number;
  report: {
    variable_metrics: {
      [key: string]: {
        value: number;
        feedback: string;
      };
    };
    constant_metrics: {
      [key: string]: {
        value: number;
        feedback: string;
      };
    };
  };
};

export function ReportsView() {
  const { setCallStatus, report } = usePraxisCall() as {
    setCallStatus: React.Dispatch<React.SetStateAction<string>>;
    report: Report;
  };
  return <ReportView report={report} setCallStatus={setCallStatus} />;
}
