import React from "react";

export const performanceColors = {
  low: {
    progressBg: "#FFEBEB",
    progress: "#FA7D7D",
    text: "#FA7D7D",
  },
  medium: {
    progressBg: "#FFEED9",
    progress: "#FCC675",
    text: "#FCC675",
  },
  high: {
    progressBg: "#E1EDE2",
    progress: "#14AE5C",
    text: "#14AE5C",
  },
};

interface CircularProgressProps {
  percentage: number;
  radius?: number;
  strokeWidth?: number;
  customText?: string;
}

const CircularProgress: React.FC<CircularProgressProps> = ({
  percentage,
  radius = 40,
  strokeWidth = 6,
  customText,
}: CircularProgressProps) => {
  const normalizedRadius = radius - strokeWidth / 2; // Adjusted for stroke width
  const circumference = 2 * Math.PI * normalizedRadius;
  const offset = circumference - (percentage / 100) * circumference;

  // Dynamic color based on percentage
  const getColor = () => {
    let color = performanceColors.low;
    if (percentage > 40) {
      color = performanceColors.medium;
    }
    if (percentage > 70) {
      color = performanceColors.high;
    }
    return color.progress;
  };

  const getBgColor = () => {
    let color = performanceColors.low;
    if (percentage > 40) {
      color = performanceColors.medium;
    }
    if (percentage > 70) {
      color = performanceColors.high;
    }
    return color.progressBg;
  };

  return (
    <div
      className="relative"
      style={{ textAlign: "center", fontFamily: "Arial, sans-serif" }}
    >
      <svg
        height={radius * 2}
        width={radius * 2}
        style={{ transform: "rotate(-90deg)" }}
      >
        {/* Background Circle */}
        <circle
          stroke={getBgColor()}
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        {/* Foreground Circle */}
        <circle
          stroke={getColor()}
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
      </svg>
      {/* Label and Percentage */}
      <div className="absolute label top-0 left-0 bottom-0 right-0 flex justify-center items-center">
        <div>{customText ? customText : `${percentage}%`}</div>
      </div>
    </div>
  );
};

export default CircularProgress;
