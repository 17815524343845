export function MessageView({
  isMe,
  message,
  isCurrent,
}: {
  isMe: boolean;
  message: any;
  isCurrent?: boolean;
}) {
  if (isMe) {
    return (
      <>
        <div
          id={message.id}
          className="max-w-[90%] md:max-w-[70%] self-end gap-2.5 flex flex-col"
        >
          <div className="justify-end flex">
            <span className="text-right text-black text-xs">You</span>
          </div>
          <div
            // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
            dangerouslySetInnerHTML={{
              __html: message.message,
            }}
            className={`message-content break-word text-black text-sm p-[15px] rounded-l-lg rounded-b-lg border border-zinc-300 ${
              isCurrent ? "!bg-[#F1ECFC] border-transparent" : ""
            }`}
          />
        </div>
      </>
    );
  }
  return (
    <>
      <div
        id={message.id}
        className="max-w-[90%] md:max-w-[70%] self-start flex-col justify-start items-start gap-1.5 flex"
      >
        <div className="justify-start items-start gap-2.5 flex">
          <span className="text-right text-black text-xs">{message.name}</span>
        </div>
        <div className="self-stretch justify-start items-start gap-2 flex">
          <div
            // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
            dangerouslySetInnerHTML={{
              __html: message.message,
            }}
            className={`message-content break-word rounded-r-lg rounded-b-lg p-[15px] bg-[#FCF2EC] text-black text-sm ${
              isCurrent ? "!bg-[#F1ECFC]" : ""
            }`}
          />
        </div>
      </div>
    </>
  );
}
