export const setToken = (token: string) => {
  // set to local storage
  localStorage.setItem("token", token);
};

export const setUser = (user: any) => {
  // set to local storage
  localStorage.setItem("user", JSON.stringify(user));
};

export const getUser = () => {
  // get from local storage
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

export const getConsent = () => {
  // get from local storage
  const consent = localStorage.getItem("consent");
  return consent ? JSON.parse(consent) : null;
};

export const setConsent = (consent: any) => {
  // set to local storage
  localStorage.setItem("consent", JSON.stringify(consent));
};

export function checkPasswordStrength(password) {
  const requirements = [
    { regex: /[a-z]/, message: "lowercase letter" },
    { regex: /[A-Z]/, message: "uppercase letter" },
    { regex: /\d/, message: "digit" },
    { regex: /[@$!%*?&]/, message: "special character" },
    { regex: /.{8,}/, message: "at least 8 characters" },
  ];
  let score = 0;
  let message = "";
  requirements.forEach((requirement) => {
    if (requirement.regex.test(password)) {
      score++;
    } else {
      message += ` ${requirement.message},`;
    }
  });
  if (score === requirements.length) {
    message = "Your password is great. Nice work!";
  } else {
    message = `Your password needs ${message.slice(0, -1)}`;
  }
  return { score: (score / requirements.length) * 100, message };
}

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s+@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const clearTokenAndUser = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

export function formatNumber(num: number) {
  const absNum = Math.abs(num);

  if (absNum >= 1e9) {
    return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (absNum >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (absNum >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "k";
  } else {
    return num.toString();
  }
}

export function getInitial(given_name: string, family_name: string) {
  if (given_name && family_name) {
    return `${given_name[0]}${family_name[0]}`;
  } else if (given_name) {
    return given_name[0];
  } else if (family_name) {
    return family_name[0];
  } else {
    return "";
  }
}

export function buildAssetUrl(tenant: string, stage: string) {
  // s3://{stage}.{tenant}.assets.praxis.com
  let url = "";
  if (stage) {
    url = `${stage}.`;
  }
  return `${url}${tenant}.assets.praxis.com`;
}

export function getTenantKey() {
  // https://goodpharma.praxispro.ai => goodpharma.praxispro.ai
  // https://goodpharma.praxispro.ai/dashboard => goodpharma.praxispro.ai

  // if env is dev then return goodpharma.praxispro.ai
  // return "goodpharma.praxispro.ai";
  // return "digitalpharma.praxispro.ai";

  // if it contains localhost or dev.app.praxispro.ai then return digital.praxispro.ai
  const url = window.location.href;
  if (url.includes("localhost") || url.includes("dev.app.praxispro.ai")) {
    return "test.praxispro.ai";
  }
  const urlArr = url.split("/");
  return urlArr[2].replace("dev.", "");
}
