import { useState } from "react";
import { useParams } from "react-router-dom";
import { UserProfileView } from "./components/UserProfileView";
import { Card } from "../../../components/Card";
import { CertificationTable } from "../components/CertificationTable";
import { Tabs } from "../../../components/Tabs";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { SortingDropdown } from "../components/SortingDropdown";
import { FilterButton } from "../components/FilterButton";
import { useCertification } from "../context/certification-context";

export const UserCertificationView = () => {
  const { users } = useCertification();
  const { userId } = useParams();
  const [tab, setTab] = useState<"certifications">("certifications");
  const [filter, setFilter] = useState<any>({});
  const user = users.find((user) => user.id === userId);

  return (
    <div className="bg-[#F3F1F2] p-4 lg:p-8">
      <div className="md:mb-4 flex items-center justify-between">
        <div>
          <div className="text-black text-2xl font-bold">
            Certification Metrics
          </div>
          <div className="text-black text-lg mt-1">
            View Certification Praxis Play sessions for your team
          </div>
        </div>
      </div>
      {/* <CertificationReview form={form} setForm={setForm} /> */}
      {/* <Link to="/certifications/review/123">View</Link> */}
      <Card>
        <UserProfileView user={user} />
        <div className="my-6 border-b-2 border-[#D5D5D5]">
          <Tabs
            tabs={[{ label: "Certifications", value: "certifications" }]}
            value={tab}
            onChange={(value) => {
              setTab(value);
            }}
          />
        </div>
        <div>
          {/** TODO: Add support for theme filter and use reusable filter component */}
          {/* <FilterView filter={filter} setFilter={setFilter} /> */}
          <div className="my-3 flex justify-between items-center">
            <div className="flex items-center gap-4">
              {/* <FilterButton count={2} label="All filters" onClick={() => {}} /> */}
              <SortingDropdown
                value={filter.sortOrder}
                onChange={(value) =>
                  setFilter({
                    ...filter,
                    sortOrder: value,
                    sortBy: "createdAt",
                  })
                }
              />

              <button
                onClick={() =>
                  setFilter({
                    search: "",
                    sortBy: "createdAt",
                    sortOrder: "desc",
                  })
                }
                className="text-sm text-[#696969] hover:text-[#696969]/70"
              >
                Clear all
              </button>
            </div>
            <div className="relative w-fit bg-[#F3F3F3] rounded-md">
              <input
                type="text"
                placeholder="Search"
                className="p-2 !pl-12 rounded-md !outline-none bg-[#F3F3F3]"
                value={filter.search}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    search: e.target.value,
                  })
                }
              />
              <SearchIcon className="w-6 h-6 absolute left-3 top-1/2 -translate-y-1/2 [&_path]:stroke-black" />
            </div>
          </div>
          <CertificationTable
            type="manager-user-table"
            userId={userId}
            filter={filter}
          />
        </div>
      </Card>
    </div>
  );
};
