export function ManagerCommentsInput({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <div className="relative bg-white pt-11 rounded-md border border-[#DEDEDE]">
      <div className="absolute top-0 left-0 w-full border border-[#DEDEDE] bg-[#B4B4B421] rounded-[7px] h-11 flex items-center px-4 text-[#242424] text-[17px] leading-[20.5px]">
        Manager Comments and Feedback
      </div>
      <div className="bg-white rounded-md">
        <textarea
          className="w-full h-full border-[0px] !outline-none rounded-md p-4"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        ></textarea>
      </div>
    </div>
  );
}
