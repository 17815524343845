import {
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/table";
import { useState } from "react";

function LoadingSkeleton({ columnLength }: { columnLength: number }) {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="bg-white [&_td]:py-3 [&_td_div]:rounded-[6px] [&_td]:border-b [&_td]border-b-[#E5E7EB]"
        >
          <td className="pl-3">
            <div className="w-16 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-40 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-40 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-40 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
          {columnLength === 7 && (
            <td className="subscription">
              <div className="w-16 h-4 bg-slate-200 animate-pulse transition duration-50" />
            </td>
          )}
          <td>
            <div className="w-10 h-4 bg-slate-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function DataTable({
  columns,
  onRowClick,
  data,
  isLoading,
}: {
  columns: any;
  onRowClick?: any;
  data: any;
  isLoading: boolean;
}) {
  console.log(data);
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    // getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <div className="flex flex-col rounded-md border border-[#DBDBDB] overflow-auto lg:h-[calc(100dvh-275px)] h-[60vh] [&>div]:bg-white">
      <Table className="bg-white table">
        <TableHeader>
          {table?.getHeaderGroups()?.map((headerGroup) => (
            <TableRow
              className="sticky top-0 z-[1] bg-[#FFA67E]/10 !border-b-[0px]"
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    className="bg-[#FFA67E]/10 text-primary2 text-sm font-semibold uppercase"
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table?.getRowModel()?.rows?.length > 0 &&
            !isLoading &&
            table?.getRowModel()?.rows?.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                onClick={() => onRowClick && onRowClick(row.original)}
                className="cursor-pointer"
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {table?.getRowModel()?.rows?.length === 0 && !isLoading && (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {isLoading && <LoadingSkeleton columnLength={columns.length} />}
      </Table>
    </div>
  );
}
