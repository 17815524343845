import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import { SortingDropdown } from "../../components/SortingDropdown";

export function FilterView({
  filter,
  setFilter,
}: {
  filter: {
    sortBy: string;
    sortOrder: string;
    search: string;
  };
  setFilter: (filter: {
    sortBy: string;
    sortOrder: string;
    search: string;
  }) => void;
}) {
  return (
    <div className="flex justify-between mb-4">
      <div className="flex items-center gap-4">
        <SortingDropdown
          className="!bg-white"
          value={filter.sortOrder}
          onChange={(value) =>
            setFilter({ ...filter, sortOrder: value, sortBy: "createdAt" })
          }
        />
        <button
          onClick={() =>
            setFilter({
              sortBy: "createdAt",
              sortOrder: "desc",
              search: "",
            })
          }
          className="text-sm text-[#696969] hover:text-[#696969]/70"
        >
          Clear all
        </button>
      </div>
      <div className="relative w-fit">
        <input
          type="text"
          placeholder="Search"
          className="p-2 !pr-8 rounded-md"
          value={filter.search}
          onChange={(e) => setFilter({ ...filter, search: e.target.value })}
        />
        <SearchIcon className="w-4 h-4 absolute right-2 top-1/2 -translate-y-1/2 [&_path]:stroke-black" />
      </div>
    </div>
  );
}
