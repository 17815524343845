import { useRef, useEffect, useState } from "react";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";

const options = [
  { label: "Pass", value: "pass", bgColor: "#14AE5C1A", textColor: "#14AE5C" },
  { label: "Fail", value: "fail", bgColor: "#FF00001A", textColor: "#FA3A1A" },
];

export function PassFailSelect({ value, onChange }) {
  const [show, setShow] = useState(false);
  const selectedOption = options?.find((opt) => opt.value === value);
  const containerRef = useRef(null);

  useEffect(() => {
    // Implement outside click handler
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setShow(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-[86px]" ref={containerRef}>
      <button
        onClick={() => setShow(!show)}
        style={{
          backgroundColor: selectedOption?.bgColor || "#C5C5C51A",
          color: selectedOption?.textColor || "#A5A5A5",
        }}
        className="w-full h-[30px] flex items-center gap-1 justify-center rounded-[5px]"
      >
        <div className="w-[40px] text-center font-medium text-[11px]">
          {selectedOption?.label || "Select"}
        </div>
        <ChevronDown className={`${show ? "rotate-[180deg]" : ""} w-4 h-4`} />
      </button>
      {show && (
        <div className="absolute z-[2] top-8 left-0 w-full bg-white shadow-lg rounded-lg flex flex-col">
          {options?.map((opt) => (
            <button
              onClick={() => onChange(opt.value)}
              style={{ color: opt.textColor }}
              className="h-[30px] flex items-center font-medium justify-center hover:bg-gray-100 text-[11px]"
            >
              {opt.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
