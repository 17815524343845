import { useEffect, useMemo, useRef, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../../certifications/components/data-table";
import { Button } from "../../../components/Button";
import { ShowToast } from "../../../services/toast";

const SAMPLE_CONTACTS = [
  { id: 1, name: "Bhrugu", phoneNumber: "+14088584056" },
  { id: 2, name: "Cam", phoneNumber: "+12153371868" },
  { id: 3, name: "Amit", phoneNumber: "+18557477626" },
];

const getColumns = ({
  onAction,
  currentCall,
}: {
  onAction: (row: any, command: string) => void;
  currentCall: any;
}) => {
  return [
    {
      accessorKey: "name",
      header: ({ column }) => {
        return <div className="p-0 min-w-[100px]">Name</div>;
      },
    },
    {
      accessorKey: "phoneNumber",
      header: "Phone Number",
    },
    {
      id: "actions",
      header: () => {
        return (
          <div className="w-full flex justify-end min-w-[100px]">Session</div>
        );
      },
      cell: ({ row }) => {
        const isCurrentCall =
          row.original.phoneNumber === currentCall?.phoneNumber;
        const command = isCurrentCall ? "hang-up" : "call";
        return (
          <div className="w-full flex justify-end min-w-[100px]">
            <Button
              variant="primary"
              onClick={() => {
                onAction(row.original, command);
              }}
              className="text-sm text-center !py-1.5 !rounded-full"
            >
              {isCurrentCall ? "Hang up" : "Call"}
            </Button>
          </div>
        );
      },
    },
  ];
};

type User = {
  name: string;
  phoneNumber: string;
  id: string;
};

export function ContactsTable() {
  const [currentCall, setCurrentCall] = useState<any>(null);
  const [agent, setAgent] = useState<any>(null);
  const contactRef = useRef<any>(null);

  const trackContact = (contact) => {
    console.log("AGENT CALL: trackContact called");
    console.log("AGENT CALL: contact fetched", contact);
    contact.onEnded((_contact) => {
      console.log("AGENT CALL: contact on ended triggered", _contact);
      _contact.clear({
        success: () => {
          console.log("AGENT CALL: contact ended and cleared");
          setCurrentCall(undefined);
          ShowToast({
            message: "Contact ended and cleared",
            type: "success",
          });
        },
        failure: (error) => {
          console.log("AGENT CALL: Failed to clear contact", error);
          setCurrentCall(undefined);
          ShowToast({
            message: "Failed to clear contact",
            type: "error",
          });
        },
      });
    });
  };

  useEffect(() => {
    console.log("window.connect", window.connect);
    window.connect.agent((agentInstance) => {
      console.log("agentInstance", agentInstance);
      setAgent(agentInstance);
    });
    window.connect.contact((contact) => {
      console.log("AGENT CALL: contact fetched", contact);
      contactRef.current = contact;
      trackContact(contact);
    });
  }, []);

  const startCall = ({ phoneNumber }) => {
    console.log("AGENT CALL: startCall called", phoneNumber);
    if (!agent) {
      alert("Agent not initialized");
      return;
    }

    const endpoint = window.connect.Endpoint.byPhoneNumber(phoneNumber);
    agent.connect(endpoint, {
      success: function () {
        console.log("AGENT CALL: outbound call connected");
      },
      failure: function (err) {
        console.log("AGENT CALL: outbound call connection failed");
        console.log(err);
        ShowToast({
          message: "Failed to connect to the contact",
          type: "error",
        });
      },
    });
  };

  const endCall = () => {
    console.log("AGENT CALL: end call called");
    console.log("AGENT CALL: contact fetched", contactRef.current);
    const agentConnection = contactRef.current.getAgentConnection();
    agentConnection.destroy({
      success: () => {
        console.log("AGENT CALL: Disconnected from the contact");
        setCurrentCall(undefined);
        ShowToast({
          message: "Disconnected from the contact",
          type: "success",
        });
      },
      failure: () => {
        console.log("AGENT CALL: Failed to disconnect from the contact");
        ShowToast({
          message: "Failed to disconnect from the contact",
          type: "error",
        });
      },
    });
  };

  const handleCall = (data, command) => {
    console.log("AGENT CALL:", data, command);
    if (command === "call") {
      // call
      setCurrentCall(data);
      startCall(data);
    } else {
      console.log("AGENT CALL: end call called");
      // hang-up
      setCurrentCall(undefined);
      endCall();
    }
  };

  // DATA fetching can be done here

  const data = useMemo(() => {
    return [...SAMPLE_CONTACTS];
  }, []);

  const columns: ColumnDef<User>[] = getColumns({
    currentCall,
    onAction: handleCall,
  });

  return (
    <div className="w-full">
      <div className="flex flex-col gap-5">
        <DataTable columns={columns} data={data} isLoading={false} />
      </div>
    </div>
  );
}
