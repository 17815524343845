export function SelectInput({
  value,
  onChange,
  options,
  className = "",
  placeholder,
}) {
  return (
    <select
      className={`border border-[#E9E9E9] rounded px-2 py-1 !outline-none ${className}`}
      onChange={onChange}
      value={value}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((option) => (
        <option key={`${option.value}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
