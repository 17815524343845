import { useState } from "react";
import { Button } from "../../../components/Button";
import { FCRReport } from "../FCRReport";
import { Tabs } from "./Tabs";
import { Performance } from "./report-tabs/Performance";
import { AIFeedback } from "./report-tabs/AIFeedback";
import { SessionRecap } from "./report-tabs/SessionRecap";
import CallAudio from "../CallAudio";
import { BackButton } from "../../../components/Button/BackButton";

export type Conversation = {
  id: string;
  message: string;
  isUserMessage: boolean;
  session_url?: string | null;
  audio_file?: any;
};

type Report = {
  conversations: Conversation[];
  languageClarity: number;
  vocabulary: number;
  brandStrategy: number;
  questionTechniques: number;
  compliancePercentage: number;
  tone: number;
  report: {
    variable_metrics: {
      [key: string]: {
        value: number;
        feedback: string;
      };
    };
    constant_metrics: {
      [key: string]: {
        value: number;
        feedback: string;
      };
    };
  };
};

export function ReportView({
  report,
  setCallStatus,
  isReport = false,
  onBackClick,
}: {
  report: Report;
  setCallStatus?: React.Dispatch<React.SetStateAction<string>>;
  isReport?: boolean;
  onBackClick?: () => void;
}) {
  const [tab, setTab] = useState("performance");
  const [showFcrReport, setShowFcrReport] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined
  );

  const variableMetrics = report?.report?.variable_metrics || {};
  const constantMetrics = report?.report?.constant_metrics || {};

  const metrics = { ...variableMetrics, ...constantMetrics };

  console.log(report);

  return (
    <div className="bg-[#F3F1F2] p-4 pt-20 lg:p-6">
      {!showFcrReport ? (
        <>
          <div className="md:mb-8 flex items-center justify-between">
            <div>
              <div className="flex items-center gap-2">
                {isReport && (
                  <BackButton
                    onClick={() => {
                      if (onBackClick) {
                        onBackClick?.();
                      } else {
                        window.history.back();
                      }
                    }}
                  />
                )}
                <div className="text-black text-2xl font-bold">
                  Praxis Play Feedback
                </div>
              </div>
              <div className="text-black text-lg mt-3">
                View your AI generated feedback for you last Praxis Play session
              </div>
            </div>
            {!isReport && (
              <Button
                variant="primary"
                className="hidden md:block !rounded-full !py-3 !px-10"
                onClick={() => setCallStatus?.("idle")}
              >
                Call Again
              </Button>
            )}
          </div>
          {!isReport && (
            <Button
              variant="primary"
              className="md:hidden my-5 w-full !rounded-full !py-3 !px-10"
              onClick={() => setCallStatus?.("idle")}
            >
              Call Again
            </Button>
          )}
          <div className="mb-5">
            <div className="relative border-b-2 border-[#D5D5D5] my-4">
              <Tabs value={tab} onChange={setTab} />
              <div className="absolute bottom-2 right-0">
                <CallAudio
                  conversations={report.conversations || []}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                />
              </div>
            </div>
            {tab === "performance" && (
              <Performance report={report} metrics={metrics} />
            )}
            {tab === "ai-feedback" && (
              <AIFeedback report={report} metrics={metrics} />
            )}
            {tab === "session-recap" && (
              <SessionRecap
                report={report}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                metrics={metrics}
              />
            )}
            {/* <ReportOverview
              report={report}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            /> */}
          </div>
        </>
      ) : (
        <FCRReport />
      )}
    </div>
  );
}
