import React, { useState } from "react";
import { api } from "../../../services/api";
import { useQuery } from "@tanstack/react-query";

export const ReportsContext = React.createContext({});

// used to fetch all the certifications, users and other data
export function ReportsProvider({ children }) {
  const [selectedReports, setSelectedReports] = useState<any>(null);
  const { data: userData } = useQuery({
    queryKey: ["users"],
    queryFn: () => api.searchUsers(),
  });

  const { data, isLoading, error } = useQuery({
    queryKey: ["certifications"],
    queryFn: () => api.searchCertifications({}),
  });

  const users = userData?.users || [];
  const certifications = data?.data?.certifications || [];

  const values = {
    certifications,
    users,
    selectedReports,
    setSelectedReports,
  };
  return (
    <ReportsContext.Provider value={values}>{children}</ReportsContext.Provider>
  );
}

export function useReports(): {
  certifications: any;
  setCertifications: React.Dispatch<React.SetStateAction<any>>;
  users: any;
  setUsers: React.Dispatch<React.SetStateAction<any>>;
  selectedReports: any;
  setSelectedReports: React.Dispatch<React.SetStateAction<any>>;
} {
  const context = React.useContext(ReportsContext);
  if (context === undefined) {
    throw new Error("useReports must be used within a ReportsProvider");
  }
  return context as {
    certifications: any;
    setCertifications: React.Dispatch<React.SetStateAction<any>>;
    users: any;
    setUsers: React.Dispatch<React.SetStateAction<any>>;
    selectedReports: any;
    setSelectedReports: React.Dispatch<React.SetStateAction<any>>;
  };
}
