import React, { useState } from "react";
import { api } from "../../../services/api";
import { useQuery } from "@tanstack/react-query";

export const CertificationContext = React.createContext({});

// used to fetch all the certifications, users and other data
export function CertificationProvider({ children }) {
  const [selectedCertificationAssignment, setSelectedCertificationAssignment] =
    useState<any>(null);
  const { data: userData } = useQuery({
    queryKey: ["users"],
    queryFn: () => api.searchUsers(),
  });

  const { data, isLoading, error } = useQuery({
    queryKey: ["certifications"],
    queryFn: () => api.searchCertifications({}),
  });

  const users = userData?.users || [];
  const certifications = data?.data?.certifications || [];

  const values = {
    certifications,
    users,
    selectedCertificationAssignment,
    setSelectedCertificationAssignment,
  };
  return (
    <CertificationContext.Provider value={values}>
      {children}
    </CertificationContext.Provider>
  );
}

export function useCertification(): {
  certifications: any;
  setCertifications: React.Dispatch<React.SetStateAction<any>>;
  users: any;
  setUsers: React.Dispatch<React.SetStateAction<any>>;
  selectedCertificationAssignment: any;
  setSelectedCertificationAssignment: React.Dispatch<React.SetStateAction<any>>;
} {
  const context = React.useContext(CertificationContext);
  if (context === undefined) {
    throw new Error(
      "useCertification must be used within a CertificationProvider"
    );
  }
  return context as {
    certifications: any;
    setCertifications: React.Dispatch<React.SetStateAction<any>>;
    users: any;
    setUsers: React.Dispatch<React.SetStateAction<any>>;
    selectedCertificationAssignment: any;
    setSelectedCertificationAssignment: React.Dispatch<
      React.SetStateAction<any>
    >;
  };
}
