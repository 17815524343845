import { Card } from "../../../../components/Card";
import CircularProgress from "../../../../components/CircularProgress";
import { ReactComponent as BadgeIcon } from "../../../../assets/icons/badge.svg";
import { ReactComponent as StarIcon } from "../../../../assets/icons/star.svg";
import { ReactComponent as UpwardIcon } from "../../../../assets/icons/upward.svg";
import { PerformanceCard } from "./PerformanceCard";
import { ReportInsights } from "../../../../constant";

export function CallInsight({
  title,
  percentage,
  radius = 40,
  strokeWidth = 6,
  customText,
}: {
  title: string;
  percentage: number;
  radius?: number;
  strokeWidth?: number;
  customText?: string;
}) {
  return (
    <div className="flex flex-col gap-4 items-center p-3 hover:bg-[#FFEFEF7D] rounded">
      <span className="text-[15px] leading-[18px] whitespace-nowrap">
        {title}
      </span>
      <CircularProgress
        percentage={percentage}
        radius={radius}
        strokeWidth={strokeWidth}
        customText={customText}
      />
    </div>
  );
}

export function Performance({
  report,
  metrics,
}: {
  report: any;
  metrics: any;
}) {
  const metricsKeys = Object.keys(metrics).filter(
    (key) => !["score"].includes(key)
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex lg:flex-row flex-col gap-4 items-stretch">
        <div className="flex flex-col md:flex-row lg:flex-col gap-4">
          <Card className="flex-1">
            <div className="text-[20px] font-medium text-black">
              Session Score
            </div>
            <div className="flex gap-2 items-center mt-1">
              <StarIcon className="w-7 h-7" />
              <div className="text-[#737373] text-[24px] font-medium">
                {report.score?.value ||
                  report?.report?.constant_metrics?.score?.value ||
                  0}
              </div>
            </div>
          </Card>
          <Card className="flex-1">
            <div className="flex gap-2 items-center">
              <div className="text-[20px] font-medium text-black">APS</div>
              <span className="text-sm font-light">(Avg. Praxis Score)</span>
            </div>
            <div className="flex gap-2 items-center mt-1 mb-5">
              <div className="flex gap-2 items-center">
                <StarIcon className="w-6 h-6" />
                <div className="text-[#737373] text-[20px] leading-[24px] font-medium">
                  {/* TODO: use avg score */}
                  {report.score?.value}
                </div>
              </div>
              <UpwardIcon className="w-[14px] h-[14px]" />
              <div className="text-[#969696] text-xs">
                <span className="text-[#14AE5C] font-bold">up 2%</span> from
                last week
              </div>
            </div>
            <BadgeIcon />
          </Card>
        </div>
        <div className="flex-1 items-stretch">
          <Card className="h-full !px-0">
            <div className="px-6 text-[20px] font-medium text-black">
              Call Insights
            </div>
            <div className="px-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
              {metricsKeys.map((metric) => (
                <CallInsight
                  title={ReportInsights[metric] || metric}
                  percentage={metrics[metric]?.value || 0}
                />
              ))}
            </div>
            {/* <CallInsight
                title="Brand Alignment"
                percentage={report?.brandStrategy?.value || 0}
              />
              <CallInsight title="Response Time" percentage={40} />
              <CallInsight
                title="Compliance"
                percentage={report?.compliancePercentage?.value || 0}
              />
              <CallInsight
                title="Vocabulary"
                percentage={report?.vocabulary?.value || 0}
              />
              <CallInsight title="Empathy" percentage={40} />
              <CallInsight
                title="Grammar"
                percentage={report?.languageClarity?.value || 0}
              />
              <CallInsight title="Active Listening" percentage={70} />
              <CallInsight
                title="Tone "
                percentage={report?.tone?.value || 0}
              />
              <CallInsight title="Challenger Sale" percentage={70} />
              <CallInsight title="Product Knowledge" percentage={70} />
              <CallInsight
                title="Questioning"
                percentage={report?.questionTechniques?.value || 0}
              /> */}
          </Card>
        </div>
      </div>
      <Card>
        <div className="text-[20px] font-medium text-black mb-3">
          Opportunities
        </div>
        <div className="flex flex-col gap-4">
          <PerformanceCard
            title="Compliance"
            description={metrics?.compliancePercentage?.feedback}
            percentage={metrics?.compliancePercentage?.value || 0}
          />
          <PerformanceCard
            title="Vocabulary"
            description={metrics?.vocabulary?.feedback}
            percentage={metrics?.vocabulary?.value || 0}
          />
        </div>
      </Card>
      <Card>
        <div className="text-[20px] font-medium text-black mb-3">Strengths</div>
        <div className="flex flex-col gap-4">
          <PerformanceCard
            title="Questioning Techniques"
            description={metrics?.questionTechniques?.feedback}
            percentage={metrics?.questionTechniques?.value || 0}
          />
          <PerformanceCard
            title="Product Knowledge "
            description={metrics?.openClosedQuestions?.feedback || "-"}
            percentage={metrics?.openClosedQuestions?.value || 0}
          />
        </div>
      </Card>
    </div>
  );
}
