import React, { useState } from "react";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";
import { CallProcessingLoadingState } from "../../praxis-call/components/CallProcessingLoadingState";

export const PraxisCallContext = React.createContext({});

export function PraxisCallProvider({ children }) {
  const [callDetails, setCallDetails] = React.useState<{
    id?: string;
    clientSecret?: string;
    isVideoRecording?: boolean;
  }>({});
  const [assignment, setAssignment] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [callStatus, setCallStatus] = React.useState("idle"); // idle, calling, connected, ended
  const [report, setReport] = React.useState<any>({});
  const [fetchingReports, setFetchingReports] = React.useState(false);
  const [transcripts, setTranscripts] = useState<
    { role: string; content: string }[]
  >([]);
  const [transcriberToken, setTranscriberToken] = React.useState<string | null>(
    null
  );

  const createTranscriberToken = async () => {
    const response = await api.getAssemblyAIToken();
    if (response?.data?.token) {
      setTranscriberToken(response?.data?.token);
    }
  };

  const fetchReports = async (
    transcripts: { role: string; content: string }[]
  ) => {
    try {
      setFetchingReports(true);
      const response = await api.fetchReport(
        callDetails?.id as string,
        transcripts
      );
      // const response = await api.getReports(callDetails?.id as string);
      setFetchingReports(false);
      if ([200, 201].includes(response.status)) {
        setReport(response.data);
      }
    } catch (error: any) {
      setFetchingReports(false);
      ShowToast({
        message:
          error?.response?.data?.message ||
          "An error occurred while fetching the reports",
        type: "error",
      });
    }
  };

  const onDisconnect = async () => {
    try {
      if (!callDetails?.id) {
        setCallStatus("ended");
        return;
      }
      setLoading(true);
      if (callDetails?.isVideoRecording) {
        const playbackResponse = await api.createPlayback({
          sessionId: callDetails?.id,
        });
      }
      // const response = await api.endCall(callDetails?.id);

      // if ([200, 201].includes(response.status)) {
      await fetchReports(transcripts);
      setTranscripts([]);
      setLoading(false);
      setCallStatus("ended");
      // } else {
      //   ShowToast({
      //     message: response?.data?.message || "An error occurred",
      //     type: "error",
      //   });
      //   setLoading(false);
      // }
    } catch (error: any) {
      setLoading(false);
      ShowToast({
        message:
          error?.response?.data?.message ||
          "An error occurred while ending the call",
        type: "error",
      });
      console.log(error);
    }
  };

  const values = {
    callDetails,
    setCallDetails,
    callStatus,
    report,
    setCallStatus,
    onDisconnect,
    transcriberToken,
    createTranscriberToken,
    fetchingReports,
    setLoading,
    assignment,
    setAssignment,
    setTranscripts,
  };
  return (
    <PraxisCallContext.Provider value={values}>
      {loading ? <CallProcessingLoadingState /> : children}
    </PraxisCallContext.Provider>
  );
}

export function usePraxisCall(): {
  assignment: any;
  setAssignment: React.Dispatch<React.SetStateAction<any>>;
  callDetails: {
    id?: string;
    isVideoRecording?: boolean;
    isCertificationMode?: boolean;
    clientSecret?: string;
  };
  setCallDetails: React.Dispatch<
    React.SetStateAction<{
      id?: string;
      isVideoRecording?: boolean;
      isCertificationMode?: boolean;
      clientSecret?: string;
    }>
  >;
  callStatus: string;
  setCallStatus: React.Dispatch<React.SetStateAction<string>>;
  report: unknown;
  setReport: React.Dispatch<React.SetStateAction<unknown>>;
  onDisconnect: () => Promise<void>;
  fetchingReports: boolean;
  transcriberToken: string | null;
  createTranscriberToken: () => Promise<void>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTranscripts: React.Dispatch<
    React.SetStateAction<{ role: string; content: string }[]>
  >;
} {
  const context = React.useContext(PraxisCallContext);
  if (context === undefined) {
    throw new Error("usePraxisCall must be used within a PraxisCallProvider");
  }
  return context as {
    assignment: any;
    setAssignment: React.Dispatch<React.SetStateAction<any>>;
    callDetails: {
      id?: string;
      isVideoRecording?: boolean;
      isCertificationMode?: boolean;
    };
    setCallDetails: React.Dispatch<
      React.SetStateAction<{
        id?: string;
        isVideoRecording?: boolean;
        isCertificationMode?: boolean;
      }>
    >;
    callStatus: string;
    fetchingReports: boolean;
    setCallStatus: React.Dispatch<React.SetStateAction<string>>;
    report: unknown;
    setReport: React.Dispatch<React.SetStateAction<unknown>>;
    onDisconnect: () => Promise<void>;
    transcriberToken: string | null;
    createTranscriberToken: () => Promise<void>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setTranscripts: React.Dispatch<
      React.SetStateAction<{ role: string; content: string }[]>
    >;
  };
}
