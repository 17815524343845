import React, { useEffect, useRef } from "react";
import "amazon-connect-streams";

console.log("amazon-connect-streams", window.connect);

const ConnectStreamsCCP = ({ ccpUrl, containerId, onInit }) => {
  const isInitialized = useRef(false);

  const initCCP = async (isLogin) => {
    if (window.connect.core.initialized) {
      console.log("Terminating old CCP session...");
      window.connect.core.terminate();
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Small delay before re-initializing
    }
    // delete existing iframe
    // const iframes = document.getElementsByName("Amazon Connect CCP");
    // if (iframes.length > 0) {
    //   for (let i = 0; i < iframes.length; i++) {
    //     iframes[i].remove();
    //   }
    // }

    window.connect.core.initCCP(document.getElementById(containerId), {
      ccpUrl, // AWS Connect CCP URL
      loginPopup: isLogin, // Open login popup if not logged in
      ...(isLogin
        ? {
            loginPopupAutoClose: true,
            loginOptions: {
              autoClose: true,
            },
          }
        : {}),
      softphone: { allowFramedSoftphone: false, disableRingtone: false },
      ccpAckTimeout: 14000,
      ccpLoadTimeout: 14000,
    });
    window.connect.getLog().setEchoLevel(window.connect.LogLevel.ERROR);
    window.connect.core.initSoftphoneManager({ allowFramedSoftphone: true });
  };

  useEffect(() => {
    if (isInitialized.current) return;
    isInitialized.current = true;
    navigator.mediaDevices.getUserMedia({ audio: true });
    initCCP(true);
    window.connect.core.onAuthorizeSuccess(() => {
      console.log("CCP INIT: onAuthorizeSuccess");
      onInit();
    });
    window.connect.core.onIframeRetriesExhausted(() => {
      console.log("CCP INIT: onIframeRetriesExhausted");
      initCCP(true);
    });
    window.connect.core.onAuthFail(function () {
      console.log("CCP INIT: onAuthFail");
      initCCP(true);
    });
    window.connect.core.onInitialized(() => {
      console.log("CCP INIT: onInitialized");
      onInit();
    });
  }, []);

  return (
    <>
      <div
        id={containerId}
        style={{
          width: "400px",
          height: "5px",
          border: "1px solid #ccc",
          // display: "none",
          visibility: "hidden",
        }}
        className="ccp-container"
      />
      <audio id="remote-audio" autoPlay></audio>
    </>
  );
};

export default ConnectStreamsCCP;
