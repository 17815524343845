import React, { useRef, useEffect, useState } from "react";
import { ReactComponent as SortingIcon } from "../../../assets/icons/sort.svg";

const options = [
  { label: "Most Recent", value: "desc" },
  { label: "Oldest", value: "asc" },
];

export function SortingDropdown({
  value,
  onChange,
  className,
}: {
  value: string;
  onChange: any;
  className?: string;
}) {
  const [show, setShow] = useState(false);
  const selectedOption = options?.find((opt) => opt.value === value);
  const containerRef = useRef(null);

  useEffect(() => {
    // Implement outside click handler
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setShow(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`relative bg-[#F3F3F3] px-3 py-1.5 rounded-[5px] ${className}`}
      ref={containerRef}
    >
      <button
        onClick={() => setShow(!show)}
        className="w-full flex items-center gap-2.5 justify-center rounded-[5px]"
      >
        <SortingIcon className={`w-6 h-6`} />
        <div className="text-center font-normal text-base">
          {selectedOption?.label || "Sort by"}
        </div>
      </button>
      {show && (
        <div className="absolute z-[2] top-10 left-0 w-full bg-white shadow-lg rounded-lg flex flex-col">
          {options?.map((opt) => (
            <button
              onClick={() => onChange(opt.value)}
              className="h-[30px] flex items-center justify-center hover:bg-gray-100 text-sm"
            >
              {opt.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
