import { userRoles } from "../../constant";
import { useUser } from "../../services/user-context";
import { ManagerCertifications } from "./manager-certification/ManagerCertification";
import { UserCertifications } from "./UserCertification";

export function Certifications() {
  const { userInfo } = useUser() as any;
  const role = userInfo?.role;
  if (role === userRoles.Person) {
    return <UserCertifications />;
  }
  return <ManagerCertifications />;
}
