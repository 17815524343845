import moment from "moment";
import { StatusCell } from "../../components/CertificationTable";
import { ImageView } from "../../../../components/Image";
import { getInitial } from "../../../../services/utils";

export const CertificationProfileView = ({
  certificationAssignment,
}: {
  certificationAssignment: any;
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-4">
        <div className="w-[70px] h-[70px] rounded-full">
          {certificationAssignment?.user?.profileImg ? (
            <ImageView
              className="w-[70px] h-[70px] rounded-full object-cover"
              src={certificationAssignment?.user?.profileImg}
              alt="user"
            />
          ) : (
            <div className="bg-primary w-[70px] h-[70px] rounded-full text-white text-lg flex justify-center items-center font-semibold">
              {getInitial(
                certificationAssignment?.user?.firstName,
                certificationAssignment?.user?.lastName
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <span className="text-[22px] text-[#242424]">
            {certificationAssignment?.user?.firstName}{" "}
            {certificationAssignment?.user?.lastName}
          </span>
          <div className="text-sm text-[#242424]">
            <span className="font-medium">Certification Review:</span>{" "}
            {certificationAssignment?.certification?.certificationName}
          </div>
          <div>
            <span className="font-medium">Date Created:</span>{" "}
            {moment(certificationAssignment?.createdAt).format(
              "MM/DD/YYYY hh:mm:ss A"
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-5">
          <span className="text-primary2 text-sm font-semibold uppercase">
            Attempts
          </span>
          <span className="text-[#242424] text-[13px] leading-[15.44px]">
            {certificationAssignment?.attemptCount} /{" "}
            {
              certificationAssignment?.certification
                ?.certificationAttemptsAllowed
            }
          </span>
        </div>
        <div className="flex items-center gap-10">
          <span className="text-primary2 text-sm font-semibold uppercase">
            Status
          </span>
          <StatusCell status={certificationAssignment?.finalStatus} />
        </div>
      </div>
    </div>
  );
};
