import { useState } from "react";
import { Card } from "../../../components/Card";
import { CertificationTable } from "../components/CertificationTable";
import { Button } from "../../../components/Button";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { CertificationFormModal } from "../components/CertificationFormModal";
import { api } from "../../../services/api";
import { useQuery } from "@tanstack/react-query";
import { FilterView } from "./components/FilterView";

export function ManagerCertifications() {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState({
    sortBy: "createdAt",
    sortOrder: "desc",
    search: "",
  });

  const { data: territories, isLoading: isLoadingTerritories } = useQuery({
    queryKey: ["territories"],
    queryFn: () => api.getTerritories(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { data: regions, isLoading: isLoadingRegions } = useQuery({
    queryKey: ["regions"],
    queryFn: () => api.getRegions(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { data: products, isLoading: isLoadingProducts } = useQuery({
    queryKey: ["products"],
    queryFn: () => api.getProducts(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { data: users, isLoading: isLoadingUsers } = useQuery({
    queryKey: ["users"],
    queryFn: () => api.getUsers(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return (
    <div className="bg-[#F3F1F2] p-4 pt-20 lg:p-8">
      <div className="md:mb-8 flex items-center justify-between">
        <div>
          <div className="text-black text-2xl font-bold">
            Certification Metrics
          </div>
          <div className="text-black text-lg mt-3">
            View Certification Praxis Play sessions for your team
          </div>
        </div>
        <div>
          <Button
            variant="primary"
            className="flex items-center gap-2 !rounded-full"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }}
          >
            <PlusIcon className="w-4 h-4 [&_path]:stroke-white" />
            New Certification
          </Button>
        </div>
      </div>
      <FilterView filter={filter} setFilter={setFilter} />
      <Card>
        <CertificationTable type="manager-dashboard" filter={filter} />
      </Card>
      {isOpen && (
        <CertificationFormModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          territories={territories?.data?.territories || []}
          regions={regions?.data?.regions || []}
          products={products?.data?.products || []}
          onSuccess={() => {
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
}
