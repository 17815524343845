import { Link } from "react-router-dom";
import { Card } from "../../../components/Card";
import { useReports } from "../context/reports-context";
import { report } from "../../praxis-call/services/data";
import moment from "moment";
import { secondsToMinute } from "../../praxis-call/utils";

export const SessionsCard = ({
  setShowSessionList,
}: {
  setShowSessionList: (value: boolean) => void;
}) => {
  const { setSelectedReports } = useReports();
  const sessions = [{ ...report }, { ...report }, { ...report }, { ...report }];

  return (
    <Card className="!px-0 !py-6 flex-1 !w-full  md:min-w-[350px]">
      <div className="flex px-6 justify-between items-center mb-[18px] gap-6">
        <div className="text-xl font-bold">Praxis Play Sessions</div>
        <div>
          <button
            className="text-primary2 hover:text-primary2/80 text-sm font-semibold cursor-pointer"
            onClick={() => setShowSessionList(true)}
          >
            VIEW ALL
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-[2px] px-4">
        {sessions.map((session, index) => (
          <Link
            key={index}
            to={`/praxisplay/session/${session.id}`}
            className="flex justify-between text-sm text-text2 items-center hover:bg-background px-3 py-[5px] rounded-lg"
            onClick={() => setSelectedReports({ ...session })}
          >
            <span>{moment(session.createdAt).format("MMM DD, YYYY")}</span>
            <span>{secondsToMinute(session.duration)} mins</span>
          </Link>
        ))}
      </div>
    </Card>
  );
};
